import React, {useState, useEffect} from 'react'
import { Link } from "react-router-dom";
import { ModalHeader, ModalBody, Modal, ModalFooter, Container, Row, Col, Form, FormGroup, Input, Card, CardBody, CardTitle, CardSubtitle, Button, Label, Collapse, CardHeader, CardFooter } from "reactstrap";
import Select from 'react-select';
import Dropzone from 'react-dropzone';
import useQuery from '../../../helpers/JIFI/useQuery';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Country, State } from 'country-state-city';
import AsyncSelect from 'react-select/async';

import superagent from 'superagent'
import {backendUrl} from '../../../helpers/consts.js'
import { useHistory } from "react-router-dom";

const emptyAddress = {
  address1: '',
  address2: '',
  phone: '',
  city: '',
  zip: '',
  country: 'Australia',
  company: '',
  province: 'Victoria',
}

const Accordian = ({children, title}) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <div id="accordion">
        <Card className="mb-1">
          <CardHeader onClick={() => setOpen(prev => !prev)} className="p-3" id="headingOne">
            <h6 className="m-0 font-14">
              <span
                style={{ cursor: "pointer" }} className={''}>
                {title}
              </span>
            </h6>
          </CardHeader>
          <Collapse isOpen={open}>
            <Card>
              <CardBody>
                {children}
              </CardBody>
            </Card>
          </Collapse>
        </Card>
      </div>
      <br/><br/>
    </>
  )
}

const StockInput = ({stock, setStock, productData}) => {
  const handleAddRow = () => {
    const prefillProduct = stock[stock.length - 1]?.product
    let prefillVariant
    if (prefillProduct) {
      prefillVariant = productData.find(val => prefillProduct === val._id)['variants'][0]._id
    }
    const item = {
      product: prefillProduct || productData[0]._id,
      variant: prefillVariant || productData[0]['variants'][0]._id,
      quantity: 0
    };
    setStock(prev => [...prev, item])
  };

  const editProductRow = (id, productId) => {
    setStock(prev => {
      let newList = [...prev]
      newList[id]['product'] = productId
      newList[id]['quantity'] = 0
      const variant = productData.find(val => `${val._id}` === `${productId}`)
      newList[id]['variant'] = variant.variants[0]?._id
      return newList
    })
  };

  const editVariantRow = (id, val) => {
    setStock(prev => {
      let newList = [...prev]
      newList[id]['variant'] = val
      newList[id]['quantity'] = 0
      return newList
    })
  };

  const editQuantityRow = (id, val) => {
    setStock(prev => {
      let newList = [...prev]
      let parsedInput = parseInt(val)
      if (parsedInput < 0) {
        parsedInput = 0
      }
      newList[id]['quantity'] = parsedInput
      return newList
    })
  };

  const removeRow = (id) => {
    setStock(prev => {
      let newList = prev.filter((item, key) => key !== id)
      return newList
    })
  }; 

  const products = productData ? productData.map(product => {
    const variants = product.variants.map(variant => {
      return {
        _id: variant._id,
        name: variant.name,
        selected: stock.findIndex(val => val.variant === variant._id)
      }
    })

    return {
      name: product.name,
      _id: product._id,
      variants
    }
  }) : []

  return <>
    <Row>
      <Col xs="4">
        Product
      </Col>
      <Col xs="4">
        Variant
      </Col>
      <Col xs="4">
        Quantity
      </Col>
    </Row>
    <br/>
    {
      productData && stock.map((line, lineKey) => {
        const product = products.find(val => {
          return val._id === line.product
        })
        const variant = product && product.variants.find(val => val._id === line.variant)
        return (
          <Row style={{padding: '12px 0'}}>
            <Col xs="4">
              <Input type="select" style={{width: '100%', height: '100%'}} onChange={(e) => editProductRow(lineKey, e.target.value)} name="products" id="products">
                {products.map((val, key) => <option selected={line.product === val._id} value={val._id}>{val.name}</option> )}
              </Input>
            </Col>
            <Col xs="4">
              <Input type="select" style={{width: '100%', height: '100%'}} onChange={(e) => editVariantRow(lineKey, e.target.value)} name="products" id="products">
                {
                  productData && 
                  stock[lineKey]['product'] && 
                  products.find(val => val._id === stock[lineKey]['product'])?.variants.map((val, key) => 
                    <option selected={line.variant === val._id} disabled={val.selected !== -1 && val.selected !== lineKey} value={val._id}>{val.name}</option> 
                  )
                }
              </Input>
              {/* {productData && productData.find(val => val._id === stock[lineKey]['product']).variants.find((val) => val._id === stock[lineKey]['variant']).name} */}
            </Col>
            <Col xs="3">
              <Input style={{width: '90%', height: '100%'}} type="number" value={stock[lineKey]['quantity']} onChange={(e) => editQuantityRow(lineKey, e.target.value)}/>
            </Col>
            <Col xs="1" >
              <Button onClick={() => removeRow(lineKey)} color="danger">
                X
              </Button>{" "}
            </Col>
          </Row>
        )
      })
    }
    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 12}}>
      <Button onClick={handleAddRow} color="primary">
        Add{" "}
      </Button>{" "}
      <span />
    </div>
  </>
}

const LocationListPage  = ({
  match: {
    params
  },
}) => {
  const history = useHistory();
  const { locationID } = params

  const [locationName, setLocationName] = useState('')
  const [code, setCode] = useState('')
  const [locationAustralian, setLocationAustralian] = useState(false)

  const [factoryOptions, setFactoryOptions] = useState([])
  const [locationFactory, setLocationFactory] = useState()
  const [locationLogisticsProvider, setLocationLogisticsProvider] = useState('')
  const [shopifyLinks, setShopifyLinks] = useState([])

  const [addressEditModal, setAddressEditModal] = useState();
  const toggleAddressEditModal = () => {
    setAddressEditModal(prev => !prev)
  }
  const [address, setAddress] = useState(emptyAddress);

  const [refresh, setRefresh] = useState(false)
  const refreshData = () => setRefresh(prev => !prev)

  const [stockPull, setStockPull] = useState([])
  const [data, productState] = useQuery({
    url: `/product`,
    refresh
  });
  const productData = data && data.data

  const [location, state] = useQuery({
    refresh,
    url: `/inventory/location/${locationID}`
  });

  const [selectedReplenLocations, setSelectedReplenLocations] = useState([])
  const [replenLocations, replenLocationStates] = useQuery({
    refresh,
    url: `/inventory/getReplenishLocations`
  })

  useEffect(() => {
    if (replenLocations !== undefined && replenLocationStates !== 'loading' && selectedReplenLocations.filter(val => val.label == undefined).length > 0) {
      console.log(replenLocations)
      setSelectedReplenLocations(prev => {
        return prev.map(replenLocation => {
          return {
            value: replenLocation._id,
            label: replenLocations?.find(val => val._id === replenLocation.value).name
          }
        })
      })
    }
  }, [replenLocations, selectedReplenLocations])

  useEffect(() => {
    if (location != undefined) {
      setLocationName(location.display_name)
      setCode(location.code)
      setLocationAustralian(location.australian)
      setLocationLogisticsProvider(location.logistics_provider)
      setShopifyLinks(location.shopify_links)
      setAddress(location.address || emptyAddress)
      setSelectedReplenLocations((location.stockDropLocations || []).map(val => ({value: val})))

      if (factoryOptions.length > 0 && location.factory !== '' && location.factory !== undefined) {
        let fac = factoryOptions.find(val => `${val._id}` === `${location.factory}`)
        if (fac !== undefined) {
          setLocationFactory({
            value: location.factory,
            label: fac.name
          })
        }
      } else {
        setLocationFactory({value: location.factory})
      }

      let items = []
      for(let variantCount of location.topupLevel){
        const item = {
          product: variantCount.variant.product,
          variant: variantCount.variant._id,
          quantity: variantCount.count.on_hand
        };
        items.push(item)
      }
      setStockPull(prev => items)

      
    }
  }, [location])

  const updateLocation = (e) => {
    e.preventDefault()

    if (location == undefined) {
      superagent.post(`${backendUrl}/inventory/createLocation`)
        .send({ 
          name: locationName, 
          code: code, 
          australian: locationAustralian,
          stock: stockPull,
          logistics_provider: locationLogisticsProvider,
          ...(locationFactory?.value != null ? {factory: locationFactory.value} : {})
        })
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
            const code = response.status
            history.replace(history.location.pathname, {
                errorStatusCode: code
            });
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
              history.replace(history.location.pathname, {
                errorStatusCode: code
              });
          } else {
            history.push(`/location/edit/${response.body._id}`)
          }
        }).catch(err => {
          console.log(err)
        });
    } else {
      superagent.put(`${backendUrl}/inventory/location/${locationID}`)
        .send({ 
          name: locationName, 
          code: code,
          australian: locationAustralian,
          stock: stockPull,
          logistics_provider: locationLogisticsProvider,
          shopify_links: shopifyLinks.map(val => ({...val, locationVariantIds: null})),
          address: address,
          stockDropLocations: selectedReplenLocations.map(val => val.value),
          ...(locationFactory?.value != null ? {factory: locationFactory.value} : {})
        })
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
            const code = response.status
            history.replace(history.location.pathname, {
                errorStatusCode: code
            });
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
              history.replace(history.location.pathname, {
                errorStatusCode: code
              });
          } else {
            history.go(0)
          }
        }).catch(err => {
          console.log(err)
        });
    }
  }

  useEffect(() => {
    superagent.post(`${backendUrl}/product/productFactories`)
      .send()
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
          const code = response.status
          history.replace(history.location.pathname, {
              errorStatusCode: code
          });
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
            history.replace(history.location.pathname, {
              errorStatusCode: code
            });
        } else {
          setFactoryOptions(response.body)
          setLocationFactory(prev => {
            console.log(prev)
            if (prev && prev !== '' && prev.value !== undefined){
              return ({
                value: prev.value,
                label: response.body.find(val => `${val._id}` === prev.value).name
              })
            }
            return prev
          })
        }
      }).catch(err => {
        console.log(err)
      });
  }, [])

  const logisticOptions = [
    {value: '', label: 'None'},
    {value: 'EWE', label: 'EWE Melbourne'},
    {value: 'EWESYD', label: 'EWE Sydney'},
    {value: 'EWEBRS', label: 'EWE Brisbane'},
    {value: 'EWEPTH', label: 'EWE Perth'},
    {value: 'UNIS', label: 'Unis'},
    {value: 'Airbox', label: 'Airbox'},

    {value: 'LaunchUtah', label: 'Launch Utah'},
    {value: 'LaunchKentucky', label: 'Launch Kentucky'},
  ]

  let [shopifySyncModalOpen, setShopifySyncModalOpen] = useState(false)

  return (
    <>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Ecommerce" breadcrumbItem={locationID != undefined ? 'Edit Location' : 'New Location'} />
            <Row>
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <CardTitle>Location Information</CardTitle>
                            <br/>
                            <Form>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label htmlFor="locationname">Location Name</Label>
                                            <Input id="locationname" name="locationname" type="text" className="form-control" value={locationName} onChange={(e) => setLocationName(e.target.value)} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label htmlFor="locationname">Code</Label>
                                            <Input id="locationname" name="locationname" type="text" className="form-control" value={code} onChange={(e) => setCode(e.target.value)} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label htmlFor="locationname">Logistics Provider</Label>
                                            <Select
                                              options={logisticOptions}
                                              value={logisticOptions.find(val => val.value === locationLogisticsProvider)}
                                              onChange={(selected) => setLocationLogisticsProvider(selected.value)}
                                            />
                                        </FormGroup>
                                        <div style={{
                                          margin: '16px 0',
                                          background: '#eee',
                                          borderRadius: 17,
                                          padding: 16
                                        }}>
                                          <h6 style={{ marginBottom: 16 }}> Shipping Address - <a href="#" onClick={toggleAddressEditModal}>edit</a></h6>
                                          <div> {address?.name} </div>
                                          <div> {address?.address1} </div>
                                          <div> {address?.address2 || ''} </div>
                                          <div> {`${address?.city || ''} ${address?.province || ''} ${address?.zip || ''}`} </div>
                                          <div> {address?.country || ''} </div>
                                          <div> {address?.phone || ''} </div>
                                        </div>
                                        {
                                          locationLogisticsProvider !== '' && (
                                            <FormGroup>
                                              <Label htmlFor="locationname">Replenishment Locations</Label>
                                              <Select
                                                isMulti
                                                options={replenLocations?.map(val => ({
                                                  value: val._id,
                                                  label: val.name
                                                }))}
                                                value={selectedReplenLocations}
                                                onChange={setSelectedReplenLocations}
                                              />
                                            </FormGroup>
                                          )
                                        }
                                    </Col>
                                    {
                                      location !== undefined && (
                                        <Col sm="6">
                                          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'stretch', flexDirection: 'column'}}>
                                            {
                                              shopifyLinks?.map(val => {
                                                return (
                                                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', border: '1px solid #ccc', borderRadius: 26, marginBottom: 16, padding: 16}}>
                                                    <div><b>{val.name}</b></div>
                                                    <div><i class="bx bxl-shopify"/> {val.store}.myshopify.com</div>
                                                    <div>Sync type: {val.sync_type}</div>
                                                  </div>
                                                )
                                              })
                                            }
                                            <div 
                                              style={{
                                                alignItems: 'center',
                                                borderStyle: 'dashed',
                                                borderWidth: '2px',
                                                borderRadius: '20px',
                                                borderColor: 'rgb(204, 204, 204)',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                height: '100%',
                                                justifyContent: 'center',
                                                padding: '20px',
                                                cursor: 'pointer',
                                              }}
                                              onClick={() => setShopifySyncModalOpen(true)}
                                            > 
                                              <i class="bx bxl-shopify"/> Link to Shopify
                                            </div>
                                          </div>
                                        </Col>
                                      )
                                    }
                                    <Col sm="12">
                                      <Accordian title="Top up amount" >
                                        <StockInput stock={stockPull} setStock={setStockPull} productData={productData} />
                                      </Accordian>
                                    </Col>
                                </Row>
                                <Button onClick={updateLocation} type="submit" color="success" className="mr-1 waves-effect waves-light">Save Changes</Button>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
      </div>
      <ShopifySyncModal 
        modal={shopifySyncModalOpen} 
        togglemodal={() => setShopifySyncModalOpen(prev => !prev)} 
        location={location}
        history={history}
        shopifyLinks={shopifyLinks}
        setShopifyLinks={setShopifyLinks}
      />
      <EditAddressModal modal={addressEditModal} togglemodal={toggleAddressEditModal} address={address} setAddress={setAddress} />
    </>
  )
}

const ShopifySyncModal = ({modal, togglemodal, history, location, shopifyLinks, setShopifyLinks}) => {
  const [stage, setStage] = useState(0)

  const [loading, setLoading] = useState(false)
  const [shopifyLocations, setShopifyLocation] = useState({})
  const [selectedLocation, setSelectedLocation] = useState()

  const [syncSettings, setSyncSetttings] = useState({
    type: 'NONE'
  })

  const currentLinkObject = {
    location_id: selectedLocation?.id,
    name: location?.name,
    store: selectedLocation?.locale,
    sync_type: syncSettings.type
  }

  console.log(currentLinkObject)

  useEffect(() => {
    superagent.post(`${backendUrl}/inventory/fetchShopifyLocations`)
      .send()
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status
        history.replace(history.location.pathname, {
            errorStatusCode: code
        });
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
            history.replace(history.location.pathname, {
              errorStatusCode: code
            });
        } else {
          setShopifyLocation(response.body)
        }
      }).catch(err => {
        console.log(err)
      });
  }, [location])

  const handleSave = () => {
    setShopifyLinks(prev => [...prev, currentLinkObject])
    togglemodal()
  }

  const stageComponents = [
    (
      <>
        <CardBody>
          <h4 className="card-title mb-4">Select Shopify Location</h4>
          <br />
          {
            Object.keys(shopifyLocations).map(locale => {
              return <div>
                <h3>{locale}.myshopify.com:</h3>
                <br/>
                <Row>
                  {
                    shopifyLocations[locale].locations.filter(val => !shopifyLinks.map(val => val.location_id).includes(val.id)).map(location => 
                      <Col sm="3">
                        <Card className='h-75'> 
                          <CardHeader>
                            <h4>{location.name}</h4>
                          </CardHeader>
                          <CardBody>
                            <div>{location.address1}</div>
                            <div>{location.city}</div>
                            <div>{location.province}</div>
                            <div>{location.country}</div>
                          </CardBody>
                          <CardFooter>
                            <Button 
                              onClick={() => {
                                setSelectedLocation({
                                  locale: locale,
                                  id: location.id
                                })
                              }} 
                              color={selectedLocation?.id === location.id ? 'success' : 'primary'}
                              className="mr-1 waves-effect waves-light"
                            >
                              {selectedLocation?.id === location.id ? 'Selected' : 'Select'}
                            </Button>
                          </CardFooter>
                        </Card>
                      </Col>
                    )
                  }
                </Row>
              </div>
            })
          }
        </CardBody>
        <ModalFooter>
          {
            loading ? (
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <Button onClick={() => selectedLocation !== undefined ? setStage(1) : ()=> {} } color={selectedLocation !== undefined ? "success" : 'secondary'} className="btn-lg btn-rounded">
                Next
              </Button>
            )
          }
        </ModalFooter>
      </>
    ),
    (
      <>
        <CardBody>
          <h4 className="card-title mb-4">Select Shopify Location</h4>
          <br />
          <FormGroup>
            <Label htmlFor="locationname">Stock Sync Type</Label>
            <Select 
              options={['NONE', 'PUSH', 'PULL'].map(val => ({value: val, label: val}))}
              value={{value: syncSettings.type, label: syncSettings.type}}
              onChange={(selected) => setSyncSetttings({...syncSettings, type: selected.value})}
            />
          </FormGroup>
        </CardBody>
        <ModalFooter>
          {
            loading ? (
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <>
                <Button onClick={() => setStage(0)} color={'warning'} className="btn-lg btn-rounded">
                  Back
                </Button>
                <Button onClick={handleSave} color={selectedLocation !== undefined ? "success" : 'secondary'} className="btn-lg btn-rounded">
                  Save
                </Button>
              </>
            )
          }
        </ModalFooter>

      </>
    )
  ]

  return (
    <Modal isOpen={modal} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" size="lg" toggle={togglemodal}>
      {stageComponents[stage]}
    </Modal>
  )
}

const EditAddressModal = ({modal, togglemodal, address, setAddress}) => {
  const apiKey = 'AIzaSyACNNxCG6Oc9WaFfFslSu3jv5qJApU9PpI'
  const {
    placePredictions,
    getPlacePredictions,
  } = useGoogle({
    apiKey: apiKey,
    options: {
      fields: ["address_component", "types", "geocode"],
      types: ["geocode"]
    }
  });

  useEffect(() => {
    const loaded = new CustomEvent('loadedPlaced', { 'detail': placePredictions.map(placeLine => ({ label: placeLine.description, value: placeLine })) });
    document.dispatchEvent(loaded)
  }, [placePredictions])

  const [selected, setSelected] = useState(null);

  const getPlaceData = async (placeID) => {
    return await new Promise((res, rej) => {
      const map = new window.google.maps.Map(document.getElementById('test-map'), { zoom: 15 });
      var request = {
        placeId: placeID,
        fields: ['address_components']
      };

      const service = new window.google.maps.places.PlacesService(map);
      service.getDetails(request, callback);

      function callback(place, status) {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          res(place)
        } else {
          rej(place)
        }
      }
    })
  }

  const loadOptions = (
    inputValue,
    callback
  ) => {
    getPlacePredictions({ input: inputValue })
    document.addEventListener("loadedPlaced", function (e) {
      callback(e.detail)
    });
  };
  const [loading, setLoading] = useState(false)

  const [fastFill, setFastFill] = useState('')
  useEffect(() => {
    if (fastFill !== '') {
      let split = fastFill.split('	')
      setName(split[0])
      setPhone(split[2])
      setAddress1(split[3])
      setAddress2(split[4])
      setCity(split[5])
      setState(split[6])
      setZip(split[7])
      setCountry(split[8])
      setFastFill('')
    }
  }, [fastFill])

  // 0: "Lisa Polon"
  // 1: "lisapolon88@yahoo.com"
  // 2: "3108942862"
  // 3: "4809 Laurelgrove Ave"
  // 4: "103"
  // 5: "Valley Village"
  // 6: "CA"
  // 7: "91607"

  const [name, setName] = useState(address.name)
  const [company, setCompany] = useState(address.company)
  const [address1, setAddress1] = useState(address.address1)
  const [address2, setAddress2] = useState(address.address2)
  const [city, setCity] = useState(address.city)
  const [zip, setZip] = useState(address.zip)
  const [phone, setPhone] = useState(address.phone)

  const [selectedState, setState] = useState(State.getStatesOfCountry(Country.getAllCountries().find(val => val.name === address.country)?.isoCode)?.find(val => val.name === address.province)?.isoCode)
  const [selectedCountry, setCountry] = useState(Country.getAllCountries().find(val => val.name === address.country)?.isoCode)

  useEffect(() => {
    setName(address.name)
    setCompany(address.company)
    setAddress1(address.address1)
    setAddress2(address.address2)
    setCity(address.city)
    setZip(address.zip)
    setPhone(address.phone)
    setState(State.getStatesOfCountry(Country.getAllCountries().find(val => val.name === address.country)?.isoCode)?.find(val => val.name === address.province)?.isoCode)
    setCountry(Country.getAllCountries().find(val => val.name === address.country)?.isoCode)
  }, [address])

  const handleStateChange = (e) => {
    setState(e.target.value)
  }

  const handleCountryChange = (e) => {
    setCountry(e.target.value)
    let newState
    try {
      newState = State.getStatesOfCountry(e.target.value)[0].isoCode
    } catch { }
    setState(newState)
  }

  useEffect(() => {
    if (selected) {
      getPlaceData(selected.value.place_id).then(res => {
        let items = res.address_components
        let streetNumberGoogleAPI, streetAddressGoogleAPI, countryGoogleAPI,
          stateGoogleAPI, unitNoGoogleAPI, suburnGoogleAPI, postcodeGoogleAPI

        items.map((item) => {
          if (item.types.includes('subpremise')) {
            unitNoGoogleAPI = item.long_name
          }
          if (item.types.includes('street_number')) {
            streetNumberGoogleAPI = item.long_name
          }
          if (item.types.includes('route')) {
            streetAddressGoogleAPI = item.long_name
          }
          if (item.types.includes('postal_code')) {
            postcodeGoogleAPI = item.long_name
          }
          if (item.types.includes('locality')) {
            suburnGoogleAPI = item.long_name
          }
          if (item.types.includes('administrative_area_level_1')) {
            stateGoogleAPI = item.long_name
          }
          if (item.types.includes('country')) {
            countryGoogleAPI = item.long_name
          }
        })

        setCountry(Country.getAllCountries().find(val => val.name === countryGoogleAPI)?.isoCode)
        setState(State.getStatesOfCountry(Country.getAllCountries().find(val => val.name === countryGoogleAPI)?.isoCode)?.find(val => val.name === stateGoogleAPI)?.isoCode)
        setCity(suburnGoogleAPI === undefined ? '' : suburnGoogleAPI)
        setZip(postcodeGoogleAPI === undefined ? '' : postcodeGoogleAPI)
        setAddress2('')
        if (streetAddressGoogleAPI) {
          if (streetNumberGoogleAPI) {
            if (unitNoGoogleAPI) {
              setAddress1(unitNoGoogleAPI + '/' + streetNumberGoogleAPI + ' ' + streetAddressGoogleAPI)
            } else {
              setAddress1(streetNumberGoogleAPI + ' ' + streetAddressGoogleAPI)
            }
          } else {
            setAddress1(streetAddressGoogleAPI)
          }
        } else {
          setAddress1('')
        }
        setSelected('')
      })
    }
  }, [selected])

  const handleSave = () => {
    const address = {
      "name": name,
      "address1": address1,
      address2: address2,
      company: company,
      "phone": phone,
      city: city,
      "zip": zip,
      "province": State.getStateByCodeAndCountry(selectedState, selectedCountry).name,
      "country": Country.getCountryByCode(selectedCountry).name,
    }
    setAddress(address)
    togglemodal()
  }

  if (loading === true) {
    return (
      <div>
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>
            Edit Shipping Details
          </ModalHeader >
          <ModalBody>
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </ModalBody>
        </div>
      </div>
    )
  }

  return (
    <Modal isOpen={modal} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" size="lg" toggle={togglemodal}>
      <div>
        <div style={{ display: 'none' }}><div id="test-map" /></div>
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>
            Edit Shipping Details
          </ModalHeader >
          <ModalBody>
            <div className="form-group row">
              <label htmlFor="example-text-input" className="col-md-2 col-form-label">Name</label>
              <div className="col-md-10">
                <input onChange={e => setName(e.target.value)} className="form-control" type="text" value={name} />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="example-text-input" className="col-md-2 col-form-label">Phone</label>
              <div className="col-md-10">
                <input onChange={e => setPhone(e.target.value)} className="form-control" type="text" value={phone} />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="example-text-input" className="col-md-2 col-form-label">Company</label>
              <div className="col-md-10">
                <input onChange={e => setCompany(e.target.value)} className="form-control" type="text" value={company} />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="example-text-input" className="col-md-2 col-form-label">Search Address</label>
              <div className="col-md-10">
                <AsyncSelect onClick={setSelected} onChange={setSelected} cacheOptions loadOptions={loadOptions} defaultOptions />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="example-text-input" className="col-md-2 col-form-label">Country</label>
              <div className="col-md-10">
                <Input onChange={e => setCountry(e.target.value)} type="select" name="select" id="exampleSelect">
                  {
                    Country.getAllCountries().map(country => <option value={country.isoCode} selected={selectedCountry === country.isoCode} > {country.name} </option>)
                  }
                </Input>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="example-text-input" className="col-md-2 col-form-label">State</label>
              <div className="col-md-10">
                <Input onChange={e => setState(e.target.value)} type="select" name="select" id="exampleSelect">
                  {
                    State.getStatesOfCountry(selectedCountry).map(state => <option value={state.isoCode} selected={selectedState === state.isoCode} > {state.name} </option>)
                  }
                </Input>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="example-text-input" className="col-md-2 col-form-label">Address</label>
              <div className="col-md-10">
                <input onChange={e => setAddress1(e.target.value)} className="form-control" type="text" value={address1} />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="example-text-input" className="col-md-2 col-form-label">Suburb</label>
              <div className="col-md-10">
                <input onChange={e => setCity(e.target.value)} className="form-control" type="text" value={city} />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="example-text-input" className="col-md-2 col-form-label">Postcode</label>
              <div className="col-md-10">
                <input onChange={e => setZip(e.target.value)} className="form-control" type="text" value={zip} />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="example-text-input" className="col-md-2 col-form-label">Apartment, suite, etc. (optional)</label>
              <div className="col-md-10">
                <input onChange={e => setAddress2(e.target.value)} className="form-control" type="text" value={address2} />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={handleSave}> Save </Button>
          </ModalFooter>
        </div>
      </div>
    </Modal>
  )
}

export default LocationListPage