import React, { Component } from "react";

import { connect } from "react-redux";

import { Link } from "react-router-dom";

// reactstrap
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import megamenuImg from "../../assets/images/megamenu-img.png";
import logo from "../../assets/images/logo.svg";
import logoLight from "../../assets/images/logo.svg";
import logoLightSvg from "../../assets/images/logo.svg";
import logoDark from "../../assets/images/logo.svg";

// import images
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";

// Redux Store
import { toggleRightSidebar } from "../../store/actions";

//i18n
import { withNamespaces } from 'react-i18next';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      isSearch: false,
      searchTerm: '',
      user: JSON.parse(localStorage.getItem("authUser"))
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleRightbar = this.toggleRightbar.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
    this.handleTermChange = this.handleTermChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleTermChange = (e) => {
    const newTerm = e.currentTarget.value.toUpperCase().replace('#', '')
    this.setState({
      searchTerm: newTerm
    })
  }

  handleSubmit = (e) => {
    if (e) e.preventDefault();
    const url = `/orders?searchTerm=${encodeURIComponent(JSON.stringify(this.state.searchTerm))}`
    this.props.history.push(url)
  } 

  toggleSearch = () => {
    this.setState({ isSearch: !this.state.isSearch });
  }
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.openLeftMenuCallBack();
  }

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar();
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <header style={{background: 'white'}} id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to={location => ({ ...location, pathname: "/dashboard" })} className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoDark} alt="" height="17" />
                  </span>
                </Link>

                <Link to={location => ({ ...location, pathname: "/dashboard" })} className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoLightSvg} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLight} alt="" height="19" />
                  </span>
                </Link>
              </div>

              <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
                data-toggle="collapse"
                onClick={this.toggleMenu}
                data-target="#topnav-menu-content">
                <i className="fa fa-fw fa-bars"></i>
              </button>

              {
                this.state.user.permissions.includes('ViewOrders') && (
                  <form onSubmit={this.handleSubmit} className="app-search d-none d-lg-block">
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..." 
                        value={this.state.searchTerm} 
                        onChange={this.handleTermChange} 
                      />
                      <span className="bx bx-search-alt"></span>
                    </div>
                  </form>
                )
              }


            {/* <Dropdown className="dropdown-mega d-none d-lg-block ml-2" isOpen={this.state.megaMenuDrp} toggle={() => { this.setState({ megaMenuDrp: !this.state.megaMenuDrp }) }}>
                <DropdownToggle className="btn header-item waves-effect" caret tag="button"> 
              {this.props.t('Mega Menu')}  {" "}
                  <i className="mdi mdi-chevron-down"></i></DropdownToggle>
                <DropdownMenu className="dropdown-megamenu">
                  <Row>
                    <Col sm={2}>
                      <Row>
                        <Col md={4}>
                          <h5 className="font-size-14 mt-0">{this.props.t('UI Components')}</h5>
                          <ul className="list-unstyled megamenu-list">
                            <li>
                              <Link to="#">{this.props.t('Lightbox')}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t('Range Slider')}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t('Sweet Alert')}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t('Rating')}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t('Forms')}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t('Tables')}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t('Charts')}</Link>
                            </li>
                          </ul>
                        </Col>
                        
                      </Row>
                    </Col>
                    <Col sm={2}>
                      <Row>
                        <Col sm={6}>
                          <h5 className="font-size-14 mt-0">{this.props.t('UI Components')}</h5>
                          <ul className="list-unstyled megamenu-list">
                            <li>
                              <Link to="#">{this.props.t('Lightbox')}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t('Range Slider')}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t('Sweet Alert')}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t('Rating')}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t('Forms')}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t('Tables')}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t('Charts')}</Link>
                            </li>
                          </ul>
                        </Col>

                        <Col sm={5}>
                          <div>
                            <img
                              src={megamenuImg}
                              alt=""
                              className="img-fluid mx-auto d-block"
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </DropdownMenu>
              </Dropdown> */}
            </div>

            <div className="d-flex">
              <div className="dropdown d-inline-block d-lg-none ml-2">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  id="page-header-search-dropdown"
                  onClick={() => { this.setState({ isSearch: !this.state.isSearch }); }}>
                  <i className="mdi mdi-magnify"></i>
                </button>
                {
                  this.state.user.permissions.includes('ViewOrders') && (
                    <div
                      className={this.state.isSearch ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
                      aria-labelledby="page-header-search-dropdown"
                    >
                      <form className="p-3" onSubmit={this.handleSubmit}>
                        <div className="form-group m-0">
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              onChange={this.handleTermChange} 
                              value={this.state.searchTerm} 
                              placeholder={this.props.t('Search') + "..."}
                              aria-label="Recipient's username"
                            />
                            <div className="input-group-append">
                              <button className="btn btn-primary" type="submit">
                                <i className="mdi mdi-magnify"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  )
                }
              </div>
              <div className="dropdown d-none d-lg-inline-block ml-1">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  onClick={this.toggleFullscreen}
                  data-toggle="fullscreen"
                >
                  <i className="bx bx-fullscreen"></i>
                </button>
              </div>

              {/* <NotificationDropdown /> */}

              <ProfileMenu user={this.props.user}/>

              {/* <div className="dropdown d-inline-block">
                <button
                  onClick={this.toggleRightbar}
                  type="button"
                  className="btn header-item noti-icon right-bar-toggle waves-effect" >
                  <i className="bx bx-cog bx-spin"></i>
                </button>
              </div> */}
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(withNamespaces()(Header));
