import React, { useMemo, useState } from 'react'
import { Col, Card, CardBody,  Container, Button, Table, Badge, Pagination, PaginationItem, PaginationLink, Row, Label, Input } from "reactstrap";
import "../datatables.scss";
import { CSVLink } from 'react-csv';
import Select from 'react-select';
import useQuery from '../../../helpers/JIFI/useQuery';
import { Link } from "react-router-dom";
import SupplyChainNav from '../../../components/supply-chain/nav.js';

const statusOptions = [
  { value: '', label: 'All' },
  { value: 'draft', label: 'Draft' },
  { value: 'in-transit', label: 'In Transit' },
  { value: 'at-port', label: 'At Port' },
  { value: 'ready', label: 'Ready' },
  { value: 'completed', label: 'Completed' },
  { value: 'cancelled', label: 'Cancelled' },
];


const perPageOptions = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 500, label: '500 - (WARNING SLOW)' },
  { value: 1000, label: '1000 - (WARNING SLOW)' },
]

const paginate = function (array, index) {
  return [
    array[index - 2],
    array[index - 1],
    array[index],
    array[index + 1],
    array[index + 2]
  ].filter(function (el) {
    return el != null;
  });
}

const TablePagination = ({ count, page, setPage, perPage, setPerPage }) => {
  const pageCount = Math.ceil(count / perPage)
  const pages = paginate(Array.from({ length: pageCount }, (v, i) => i + 1), page)
  const handleNumberClicked = (key) => {
    setPage(key)
  }
  return (
    <Pagination className="pagination pagination-rounded justify-content-end mb-2">
      <div style={{ height: '100%', width: 140 }}>
        <Select
          options={perPageOptions}
          onChange={newPerPage => setPerPage(newPerPage.value)}
          value={perPageOptions.find(val => val.value === perPage)}
        />
      </div>
      <PaginationItem disabled={page === 1}>
        <PaginationLink previous onClick={() => setPage(page - 1)} />
      </PaginationItem>
      {
        pages.map(val => (
          <PaginationItem onClick={() => handleNumberClicked(val)} active={val === page}>
            <PaginationLink>
              {val}
            </PaginationLink>
          </PaginationItem>
        ))
      }
      <PaginationItem>
        <PaginationLink next onClick={() => setPage(page + 1)} />
      </PaginationItem>
    </Pagination>
  )
}

const StatusToBadge = ({ status }) => {
  const conversion = {
    'draft': 'secondary',
    'packing': 'secondary',
    'in-transit': 'warning',
    'at-port': 'warning',
    'ready': 'success',
    'completed': 'success',
    'cancelled': 'succedangerss',
  }
  return <Badge className={"font-size-12 badge-soft-" + conversion[status]} color={conversion[status]} pill>
    <i className={"fab " + " font-size-12 badge-soft-primary"}></i> {status?.replace('-', ' ')?.toUpperCase()}
  </Badge>
}


const PullPage = () => {
  const [refresh, setRefresh] = useState(true)

  /*
    Pagination
  */
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10);
  const [status, setStatus] = useState('');
  const [location, setLocation] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const  dateString = (new Date()).toDateString().replace(' ', '-').replace(' ', '-').replace(' ', '-')
  const [replenishHistory, stateReplenishHistory] = useQuery({
    url: `/inventory/fetchContainer`,
    refresh,
    queryData: {
      status,
      page,
      location,
      perPage,
      searchTerm
    }
  });

  const [locations, stateLocations] = useQuery({
    url: `/inventory/locationsOptions`,
  });


  
  const replenishHistoryData = replenishHistory?.replenishObject
  const totalCount = replenishHistory?.totalCount
  let locationsOptions = useMemo(() => 
    locations?.map(val => ({ value: val.code, label: val.name })) ?? []
    , [locations]) 

  const handleKeyDown = (e) => {
    
    if (e.key === 'Enter' || e.keyCode === 13) {
      setSearchTerm(e.target.value);
    }
  }

  const csvData = [
    [
      'Supplier Ref',
      'Container Ref',
      'Arrival Date',
      'Port Date',
      'Container Status',
      'Landing Location',
      'Freight Cost',
      'Local Cost'
    ],
    ...(replenishHistoryData?.length > 0 ? replenishHistoryData.map(line => [

      line['name'],
      line['ref'],
      (new Date(line.arrivalDate)).toDateString(),
      (new Date(line.portDate)).toDateString(),
      line['status'],
      line['landingLocation']?.name,
      line['freightCost'],
      line['localCost']

    ]
    ): [])
  ]
  
  return <SupplyChainNav>
    <Container fluid>
      <Col xs="12" m="12" lg="12" xl="12">
        <Card>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1.25rem' }}>
              <Link to='create-container'> <Button color="success"> Create </Button> </Link>
              <CSVLink
                style={{ float: 'right' }}
                data={csvData}
                filename={`CONTAINERS-${dateString}.csv`}
              >
                <Button type="button" color="secondary" className="btn-md btn-rounded">
                  CSV
                </Button>
              </CSVLink>
            </div>

            <Row>
                  <Col>
                    <Label className="control-label">FILTER</Label>
                    <Row>
                      <Col xs="3">
                    
                        <div className="search-box mb-2 d-block">
                          <div className="position-relative">
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Search..."
                              onKeyDown={handleKeyDown}
                            />
                            <i className="bx bx-search-alt search-icon"></i>
                          </div>
                        </div>
                    
                      </Col>
                      <Col xs="3">
                        <Select
                          options={statusOptions}
                          onChange={(val) => {
                            setPage(1);

                            setStatus(val.value);
                          }}
                          value={statusOptions.find(
                            (val) => val.value === status
                          )}
                        ></Select>

                       
                    </Col>
                    <Col xs="3">  
                    <Select
                          onChange={(val) => {
                            setPage(1);
                            setLocation(val.value);
                          }}
                          options={locationsOptions}></Select> 
                          </Col>
                    </Row>

                  </Col>
                </Row>
            

            {
              stateReplenishHistory === 'success' ? (
                <div className="table-responsive">
                  <Table className="table table-centered table-nowrap">
                    <thead className="thead-light">
                      <tr>
                        <th>Supplier Ref</th>
                        <th>Container Ref</th>
                        <th>Arrival Date</th>
                        <th>Port Date</th>
                        <th>Container Status</th>
                        <th>Landing Location</th>
                        <th>Freight Cost</th>
                        <th>Local Cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        replenishHistoryData && replenishHistoryData.length > 0 && replenishHistoryData.map(replenishHistoryObject => {
                          return (
                            <tr key={replenishHistoryObject['_id']}>
                              <td><Link to={`/container/${replenishHistoryObject['_id']}`} className="text-body font-weight-bold">{replenishHistoryObject['name']}</Link></td>
                              <td>{replenishHistoryObject['ref']}</td>
                              <td>{(new Date(replenishHistoryObject.arrivalDate)).toDateString()}</td>
                              <td>{(new Date(replenishHistoryObject.portDate)).toDateString()}</td>
                              <td><StatusToBadge status={replenishHistoryObject['status']} /> </td>
                              <td>{replenishHistoryObject['landingLocation']?.name}</td>
                              <td>{replenishHistoryObject['freightCost']}</td>
                              <td>{replenishHistoryObject['localCost']}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <div className="spinner-border text-primary m-1" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>

              )
            }

            <TablePagination count={totalCount} page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} />
          </CardBody>
        </Card>
      </Col>
    </Container>
  </SupplyChainNav>
}

export default PullPage
