import React, { Component } from "react";
import { Row, Col, Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";

import moment from 'moment'

//i18n
import { withNamespaces } from 'react-i18next';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: JSON.parse(localStorage.getItem("authUser"))
        };
    }

    componentDidMount() {
        var matchingMenuItem = null;
        var ul = document.getElementById("navigation");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }

    activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;
        if (parent) {
            parent.classList.add("active"); // li
            const parent2 = parent.parentElement;
            parent2.classList.add("active"); // li
            const parent3 = parent2.parentElement;
            if (parent3) {
                parent3.classList.add("active"); // li
                const parent4 = parent3.parentElement;
                if (parent4) {
                    parent4.classList.add("active"); // li
                    const parent5 = parent4.parentElement;
                    if (parent5) {
                        parent5.classList.add("active"); // li
                        const parent6 = parent5.parentElement;
                        if (parent6) {
                            parent6.classList.add("active"); // li
                            const parent7 = parent6.parentElement;
                            if (parent7) {
                                parent7.classList.add("active"); // li
                            }
                        }
                    }
                }
            }
        }
        return false;
    };

    render() {
        return (
            <React.Fragment>
                <div style={{ background: 'rgb(31, 62, 60)', top: 0 }} className="topnav">
                    <div className="container-fluid">
                        <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
                            <Collapse isOpen={this.props.menuOpen} className="navbar-collapse" id="topnav-menu-content">
                                <ul className="navbar-nav">
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link" to="/dashboard">
                                            <i className="bx bx-home-circle mr-2"></i>{this.props.t('Dashboard')} {this.props.menuOpen}
                                        </Link>
                                    </li>

                                    {
                                        (
                                            this.state.user.permissions.includes('ViewOrders') ||
                                            this.state.user.permissions.includes('ViewDeliveries') ||
                                            this.state.user.permissions.includes('CreateOrder') ||
                                            this.state.user.permissions.includes('UNISPersonalisation') ||
                                            this.state.user.permissions.includes('AirboxPersonalisation')
                                        ) && (
                                            <li className="nav-item dropdown">
                                                <Link to="/#" onClick={e => { e.preventDefault(); this.setState({ fulfillmentState: !this.state.fulfillmentState }); }} className="nav-link dropdown-toggle arrow-none"  >
                                                    <i className="bx bx-box"></i> Fulfillment <div className="arrow-down"></div>
                                                </Link>
                                                <div style={{ background: 'rgb(31, 62, 60)' }} className={classname("dropdown-menu", { show: this.state.fulfillmentState })} >
                                                    {
                                                        this.state.user.permissions.includes('ViewOrders') && <>
                                                            <Link style={{ background: 'rgb(31, 62, 60)' }} to="/orders" className="dropdown-item">All Orders</Link>
                                                            {/* <Link to={`/orders?startDate=${moment().format('YYYY-MM-DD')}&endDate=${moment().subtract(1, 'days').format('YYYY-MM-DD')}`} className="dropdown-item">Daily Orders</Link> */}
                                                            <Link style={{ background: 'rgb(31, 62, 60)' }} to="/corp-orders" className="dropdown-item">Corporate Orders</Link>
                                                            <Link style={{ background: 'rgb(31, 62, 60)' }} to="/awaiting-stock" className="dropdown-item">Awaiting Stock Summary</Link>
                                                            <Link style={{ background: 'rgb(31, 62, 60)' }} to="/eiz" className="dropdown-item">EIZ Manifests</Link>
                                                        </>
                                                    }
                                                    {
                                                        this.state.user.permissions.includes('ViewReturns') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/returns" className="dropdown-item">Repairs</Link>
                                                    }

                                                    {
                                                        this.state.user.permissions.includes('UNISPersonalisation') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/external-personalisation" className="dropdown-item">UNIS Personalisation</Link>
                                                    }
                                                    {
                                                        this.state.user.permissions.includes('LaunchPersonalisation') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/external-personalisation-launch" className="dropdown-item">Launch Personalisation</Link>
                                                    }

                                                    {
                                                        this.state.user.permissions.includes('ViewOrders') && <>
                                                            <Link style={{ background: 'rgb(31, 62, 60)' }} to="/external-personalisation-ewe" className="dropdown-item">EWE Personalisation</Link>
                                                        </>
                                                    }
                                                    {
                                                        this.state.user.permissions.includes('AirboxPersonalisation') && <>
                                                            <Link style={{ background: 'rgb(31, 62, 60)' }} to="/external-personalisation-airbox" className="dropdown-item">Airbox Personalisation</Link>
                                                        </>
                                                    }
                                                    {
                                                        this.state.user.permissions.includes('CreateOrder') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/order/create" className="dropdown-item">Create Order</Link>
                                                    }
                                                </div>
                                            </li>
                                        )
                                    }

                                    {
                                        (
                                            this.state.user.permissions.includes('ViewTotalCount') ||
                                            this.state.user.permissions.includes('ViewContainer')
                                        ) && (
                                            <li className="nav-item dropdown">
                                                <Link to="/#" onClick={e => { e.preventDefault(); this.setState({ auditState: !this.state.auditState }); }} className="nav-link dropdown-toggle arrow-none"  >
                                                    <i className="bx bx-list-check"></i> Stock Levels <div className="arrow-down"></div>
                                                </Link>
                                                <div style={{ background: 'rgb(31, 62, 60)' }} className={classname("dropdown-menu", { show: this.state.auditState })} >
                                                    {/* <Link style={{background: 'rgb(31, 62, 60)'}} to="/products" className="dropdown-item">Products Levels</Link> */}
                                                    {
                                                        this.state.user.permissions.includes('ViewTotalCount') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/all-stock" className="dropdown-item">Total Stock</Link>
                                                    }
                                                    {
                                                        this.state.user.permissions.includes('ViewContainer') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/upcoming-container" className="dropdown-item">Upcoming Containers</Link>
                                                    }

                                                    {
                                                        this.state.user.permissions.includes('ViewTotalCount') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/stock-warning" className="dropdown-item">Stock Warnings</Link>
                                                    }

                                                </div>
                                            </li>
                                        )
                                    }
                                    {
                                        (
                                            this.state.user.permissions.includes('CreateApprovals') ||
                                            this.state.user.permissions.includes('TransferStock') ||
                                            this.state.user.permissions.includes('BookStockDrop') ||
                                            this.state.user.permissions.includes('ViewMovementHistory')
                                        ) && (
                                            <li className="nav-item dropdown">
                                                <Link to="/#" onClick={e => { e.preventDefault(); this.setState({ inventoryState: !this.state.inventoryState }); }} className="nav-link dropdown-toggle arrow-none"  >
                                                    <i className="bx bx-transfer"></i> Inventory <div className="arrow-down"></div>
                                                </Link>
                                                <div style={{ background: 'rgb(31, 62, 60)' }} className={classname("dropdown-menu", { show: this.state.inventoryState })} >
                                                    {
                                                        this.state.user.permissions.includes('CreateApproval') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/stock" className="dropdown-item">Stock Audit</Link>
                                                    }
                                                    {
                                                        this.state.user.permissions.includes('TransferStock') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/stock/transfer" className="dropdown-item">Transfer Stock</Link>
                                                    }
                                                    {
                                                        this.state.user.permissions.includes('BookStockDrop') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/stock/replenish" className="dropdown-item">Store Replenishments</Link>
                                                    }
                                                    {
                                                        this.state.user.permissions.includes('BookStockDrop') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/stock/warehouse-transfer" className="dropdown-item">Warehouse Transfers</Link>
                                                    }
                                                    {
                                                        this.state.user.permissions.includes('ViewMovementHistory') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/movement-history" className="dropdown-item">Movement History</Link>
                                                    }
                                                </div>
                                            </li>
                                        )
                                    }
                                    {
                                        (
                                            this.state.user.permissions.includes('ViewApprovals') ||
                                            this.state.user.permissions.includes('CreateASN') ||
                                            this.state.user.permissions.includes('ViewDemand')
                                        ) && (
                                            <li className="nav-item dropdown">
                                                <Link to="/#" onClick={e => { e.preventDefault(); this.setState({ financeState: !this.state.financeState }); }} className="nav-link dropdown-toggle arrow-none"  >
                                                    <i className="bx bx-transfer"></i> Finance <div className="arrow-down"></div>
                                                </Link>
                                                <div style={{ background: 'rgb(31, 62, 60)' }} className={classname("dropdown-menu", { show: this.state.financeState })} >
                                                    {
                                                        this.state.user.permissions.includes('ViewApprovals') && <>
                                                            <Link style={{ background: 'rgb(31, 62, 60)' }} to="/approvals" className="dropdown-item">Audit Approvals</Link>
                                                        </>
                                                    }
                                                    {
                                                        this.state.user.permissions.includes('ViewDemand') && <>
                                                            <Link style={{ background: 'rgb(31, 62, 60)' }} to="/forecast" className="dropdown-item">Forecast</Link>
                                                            <Link style={{ background: 'rgb(31, 62, 60)' }} to="/demand/stock-status" className="dropdown-item">Historic Stock Status</Link>
                                                            <Link style={{ background: 'rgb(31, 62, 60)' }} to="/demand/stock-status-month" className="dropdown-item">Historic Stock Status Months</Link>
                                                        </>
                                                    }
                                                </div>
                                            </li>
                                        )
                                    }
                                    {/* {
                                        (
                                            this.state.user.permissions.includes('ViewAnalytics')
                                        ) && (
                                            <li className="nav-item dropdown">
                                                <Link to="/#" onClick={e => { e.preventDefault(); this.setState({ analyticsState: !this.state.analyticsState }); }} className="nav-link dropdown-toggle arrow-none"  >
                                                    <i className="bx bx-transfer"></i> Analytics <div className="arrow-down"></div>
                                                </Link>
                                                <div style={{ background: 'rgb(31, 62, 60)' }} className={classname("dropdown-menu", { show: this.state.analyticsState })} >
                                                    <Link style={{ background: 'rgb(31, 62, 60)' }} to="/analytics/stock-transfer-efficency" className="dropdown-item">Stock Transfer efficency</Link>
                                                    <Link style={{ background: 'rgb(31, 62, 60)' }} to="/analytics/stock-over-time" className="dropdown-item">Variant Performance</Link>
                                                    <Link style={{ background: 'rgb(31, 62, 60)' }} to="/analytics/product-over-time" className="dropdown-item">Product Performance</Link>
                                                    <Link style={{ background: 'rgb(31, 62, 60)' }} to="/analytics/monogram-performance" className="dropdown-item">Monogram Performance</Link>

                                                </div>
                                            </li>
                                        )
                                    } */}
                                    {
                                        (
                                            this.state.user.permissions.includes('EditProductVariant') ||
                                            this.state.user.permissions.includes('EditLocations') ||
                                            this.state.user.permissions.includes('EditUser') ||
                                            this.state.user.permissions.includes('ViewFactories') ||
                                            this.state.user.permissions.includes('ViewPurchaseOrders')
                                        ) && (
                                            <li className="nav-item dropdown">
                                                <Link to="/#" onClick={e => { e.preventDefault(); this.setState({ settingState: !this.state.settingState }); }} className="nav-link dropdown-toggle arrow-none"  >
                                                    <i className="bx bx-transfer"></i> Settings <div className="arrow-down"></div>
                                                </Link>
                                                <div style={{ background: 'rgb(31, 62, 60)' }} className={classname("dropdown-menu", { show: this.state.settingState })} >

                                                    {
                                                        this.state.user.permissions.includes('EditProductVariant') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/product/all" className="dropdown-item">Products</Link>
                                                    }
                                                    {
                                                        this.state.user.permissions.includes('EditLocations') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/location/all" className="dropdown-item">Locations</Link>
                                                    }
                                                    {
                                                        this.state.user.permissions.includes('EditUser') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/users/all" className="dropdown-item">Users</Link>
                                                    }
                                                    {
                                                        this.state.user.permissions.includes('EditReturns') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/parcels" className="dropdown-item">Parcels</Link>
                                                    }
                                                    {
                                                        this.state.user.permissions.includes('EditFlagType') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/flagtype/all" className="dropdown-item">Flag Type</Link>
                                                    }
                                                    {
                                                        // this.state.user.permissions.includes('EditFlagType') &&
                                                        <Link style={{ background: 'rgb(31, 62, 60)' }} to="/cog" className="dropdown-item">COG</Link>
                                                    }
                                                    {
                                                        this.state.user.permissions.includes('ViewFactories') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/factories" className="dropdown-item">Factories</Link>
                                                    }
                                                    {
                                                        this.state.user.permissions.includes('ViewPurchaseOrders') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/purchase-order" className="dropdown-item">Purchase Orders</Link>
                                                    }
                                                </div>
                                            </li>
                                        )
                                    }
                                </ul>
                            </Collapse>
                        </nav>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(withNamespaces()(Navbar));
