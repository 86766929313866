import React, { Component, Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, CustomInput, CardTitle, Input, FormGroup, Label, Button, TextArea } from "reactstrap";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { uploadFileS3Bucket } from "../../../utilities/uploadFileS3Bucket";
import superagent from 'superagent'
import {backendUrl} from '../../../helpers/consts.js'

const AddComment = ({ orderName, refresh, commentType }) => {
    const history = useHistory();
    const initialState = {
        clientId: "",
        userName: "",
        user: "",
        orderName: "",
        title: "",
        description: "",
        comment_file: null,
        isFileUploaded: false,
        commentType: 'General'
    }
    const [state, setState] = useState(initialState);

    const endpointLookup = {
        Dashboard: `General`,
        Order: `Order`,
        Container: `Container`,

        Factory: `Factories`,
        PurchaseOrder: `PurchaseOrders`,
        Release: `Releases`,
    }

    useEffect(() => {
        let userData = localStorage.getItem("authUser");
        if (userData) {
            userData = JSON.parse(userData);
            let userName = userData.userName;
            let user = userData._id;
            setState((prevState => {
                return {
                    ...prevState,
                    userName: userName,
                    user: user,
                    orderName: orderName ? orderName.split('-')[0] : "",
                    orderNameFull: orderName ? orderName : "",
                    commentType: endpointLookup[commentType]
                }
            }))
        }
    }, [orderName]);

    const handleInputChange = (name, value) => {
        setState((prevState => {
            return {
                ...prevState,
                [name]: value
            }
        }))
    };

    const handleFileChange = ({ target: { files } }) => {
        const cancel = !files.length;
        if (cancel) return;
        setState((prevState => {
            return {
                ...prevState,
                comment_file: files[0],
                isFileUploaded: true
            }
        }))
    }

    const handleCreateComment = async (e) => {
        e.preventDefault();
        const { commentType, orderName, userName, title, description, comment_file, isFileUploaded, user, orderNameFull } = state;

        let commentFields = {
            orderName: orderName,
            orderNameFull: orderNameFull,
            userName: userName,
            files: null,
            title: title,
            description: description,
            comment_type: commentType,
            user: user
        };

        let [commentFile] = await Promise.all([
            isFileUploaded ? uploadFileS3Bucket(comment_file, `comment_file_${Date.now()}`) : comment_file
        ])

        commentFields = { ...commentFields, files: commentFile ? [commentFile] : [] };
        superagent.post(`${backendUrl}/comments`)
            .send(commentFields)
            .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
            .set('accept', 'json')
            .on('error', (response) => {
                const code = response.status
                history.replace(history.location.pathname, {
                    errorStatusCode: code
                });
            })
            .then(response => {
                const code = response.status
                if (code > 400) {
                    history.replace(history.location.pathname, {
                        errorStatusCode: code
                    });
                } else {
                    refresh()
                    toast.success("Comment created successfully");
                    setState((prevState => {
                        return {
                            ...prevState,
                            title: "",
                            description: "",
                            comment_file: null,
                            isFileUploaded: false
                        }
                    }))
                }
            }).catch(err => {
                console.log(err)
            });
    };

    toast.configure({ autoClose: 8000, hideProgressBar: true })
    const { title, description, comment_file } = state;

    return (
        <form className="outer-repeater">
            <div data-repeater-list="outer-group" className="outer">
                <div data-repeater-item className="outer">
                    <FormGroup className="mb-4" row>
                        <Col lg="12" className="d-flex align-items-center custome-input">
                            <Input name="description" type="textarea" className="form-control" placeholder="Enter Comment..." value={description}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value)} />
                        </Col>
                        <Col lg="12" className="d-flex mt-2" style={{marginLeft: -8, display: 'flex'}}>
                            <CustomInput
                                accept="*"
                                className=" ml-2"
                                type="file"
                                id="comment_file"
                                name="comment_file"
                                label={comment_file || 'Add image or file'}
                                onChange={(e) => handleFileChange(e)}
                            />

                            <Button style={{width: '15%', marginRight: -8, minWidth: 60}} className="ml-2" onClick={(e) => handleCreateComment(e)} type="submit" color="primary">Post</Button>
                        </Col>
                    </FormGroup>
                </div>
            </div>
        </form>
    );
}

export default AddComment;