import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { FormGroup, Container, Input, Button, Table, Label, Badge, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, Pagination, PaginationItem, PaginationLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { useUrlState } from "../../../helpers/useUrlState";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import moment from 'moment';
import useQuery from "../../../helpers/JIFI/useQuery";
import ReactSelect from "react-select";
import { CSVLink, CSVDownload } from "react-csv";

const StockStatus = () => {
  const initialState = {
    orderStartDate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
    filterStartDate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
    orderEndDate: moment().format('YYYY-MM-DD'),
    filterEndDate: moment().format('YYYY-MM-DD'),
    region: 'OnlineAU'
  }
  const [stateData, setStateData] = useUrlState(initialState);
  const [refresh, setRefresh] = useState(true)
  const refreshData = () => {
    setRefresh(prev => !prev)
  }

  const handleChange = (e) => {
    e.persist();
    setStateData((prevState => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
        filterStartDate: e.target.name === 'orderStartDate' ? moment(e.target.value).format('YYYY-MM-DD') : prevState.filterStartDate,
        filterEndDate: e.target.name === 'orderEndDate' ? moment(e.target.value).format('YYYY-MM-DD') : prevState.filterEndDate
      }
    }))
  };

  const { orderStartDate, orderEndDate, region } = stateData;

  const [stockStatus, stockStatusState] = useQuery({
    url: `/demand/search-stock-status`,
    refresh,
    queryData: {
      startDate: orderStartDate,
      endDate: orderEndDate,
      region
    }
  })

  const [searchTerm, setSearchTerm] = useState('')
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [parseProducts, setParsedProducts] = useState([])
  const fullSelectedProduct = parseProducts.find(product => product.id === selectedProduct?.value)
  const productOptions = parseProducts.map(product => ({ value: product.id, label: product.name }))
  const [products, productState] = useQuery({
    url: `/product/findAll`,
    refresh,
  });
  useEffect(() => {
    if (products && productState === 'success') {
      let tempProd = products?.data?.map(product => {
        let variants = []
        product.variants.map(variant => {
          variants.push(variant.sku)
        })
        return {
          id: product._id,
          name: product.name,
          variants,
          variantsFull: product.variants,
          category: product.category
        }
      }) || []

      tempProd = tempProd.sort((a, b) => {
        // Compare by category
        if (a.category < b.category) return -1;
        if (a.category > b.category) return 1;

        // If categories are equal, compare by name
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;

        return 0;
      });

      setParsedProducts(tempProd)
    }
  }, [products])

  const loadingState = stockStatusState === 'success' && productState === 'success'

  const regeionOptions = [
    'OnlineAU',
    'OnlineUS',
    'OnlineUK',
    'AllRetail',
    'RetailPacfair',
    'RetailChadstone',
    'RetailCollingwood',
    'RetailGaleries',
    'RetailNy',
    'RetailQv',
    'Retail80Collins',
    'RetailBondi',
    'RetailPitt'
  
  ]
  const setRegion = (val) => {
    setStateData(prev => ({
      ...prev,
      region: val
    }))
  }

  const parsedLines = stockStatus?.sort((a, b) => a._id - b._id).map(status => {
    const lines = status.lines.map((line, i) => {
      const velocity = Math.round(line.salesVelocity / line.count * 100) / 100
      if (status._id === '' || status._id === '.') {
        return undefined
      }
      if (fullSelectedProduct && !fullSelectedProduct.variants.includes(status._id)) {
        return undefined
      }
      if (searchTerm !== '' && !status._id.toUpperCase().includes(searchTerm.toUpperCase())) {
        return undefined
      }
      const product = parseProducts?.find(product => product.variants.includes(status._id))
      const fullVariant = product?.variantsFull.find(variant => variant.sku === status._id)
      return {
        sku: status._id,
        region: line._id.region,
        status: line._id.status,
        count: line.count,
        velocity,
        productCategory: product?.category,
        productName: product?.name, 
        price: (fullVariant?.shopifyData || {})[region.replace('Online', '')]?.price,
      }
    })
    return lines
  }).flat().filter(val => val).sort((a,b) => a?.productCategory?.localeCompare(b?.productCategory))

  const csvData = [
    ['Category', 'Product', 'SKU', 'Price', 'Store', 'Status', 'No of Days', 'Sales Velocity'],
    ...(parsedLines?.map(line => [line.productCategory, line.productName, line.sku, line.price, line.region, line.status, line.count, line.velocity]) || [])
  ]

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Fulfillment" breadcrumbItem="Stock Status" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="12">
                      <Col>
                        <Row>
                          <Col sm="10" style={{paddingBottom: 8}}>
                            <div className="app-search d-none d-lg-block">
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search..." 
                                  value={searchTerm} 
                                  onChange={(e) => setSearchTerm(e.target.value)} 
                                />
                                <span className="bx bx-search-alt"></span>
                              </div>
                            </div>
                          </Col>
                          <Col sm="2" style={{padding: 16}}>
                            <CSVLink 
                              style={{float: 'right'}}
                              data={csvData}
                              filename={`STOCK_STATUS.csv`}
                            >
                              <Button type="button" color="secondary" className="btn-lg btn-rounded">
                              CSV
                              </Button>
                            </CSVLink>
                          </Col>

                        </Row>
                        <Row>
                          <Col sm="3">
                            <FormGroup className="select2-container">
                              <Label className="control-label">STARTING</Label>
                              <input className="form-control" name="orderStartDate" value={orderStartDate || ''} onChange={handleChange} type="date" id="example-date-input" />
                            </FormGroup>
                          </Col>
                          <Col sm="3">
                            <FormGroup className="select2-container">
                              <Label className="control-label">ENDING</Label>
                              <input className="form-control" name="orderEndDate" value={orderEndDate || ''} onChange={handleChange} type="date" id="example-date-input" />
                            </FormGroup>
                          </Col>
                          <Col sm="3">
                            <FormGroup className="select2-container">
                              <Label className="control-label">Region</Label>
                              <ReactSelect
                                options={regeionOptions.map(val => ({ value: val, label: val }))}
                                onChange={val => setRegion(val.value)}
                                value={{ value: region, label: region }}
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="3">
                            <FormGroup className="select2-container">
                              <Label className="control-label">Product</Label>
                              <ReactSelect
                                options={productOptions}
                                onChange={setSelectedProduct}
                                value={selectedProduct}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <Table className="table table-centered table-nowrap">
                      <thead className="thead-light">
                        <tr>
                          <th>Category</th>
                          <th>Product</th>
                          <th>SKU</th>
                          <th>Price</th>
                          <th>Store</th>
                          <th>Status</th>
                          <th>No of Days</th>
                          <th>Sales Velocity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          loadingState ? (
                            parsedLines?.map((status, i) => {
                              return <tr className="py-8" style={{ padding: '16px 0' }} key={"_line_" + `${i}_` + `${status.sku}`}>
                                <td> {status.productCategory} </td>
                                <td> {status.productName} </td>
                                <td> {status.sku} </td>
                                <td> {status.price} </td>
                                <td> {status.region} </td>
                                <td> {status.status} </td>
                                <td> {status.count} </td>
                                <td> {status.velocity} </td>
                              </tr>
                            })
                          ) : (
                            (
                              [...Array(10)].map((_, i) => (
                                <tr className="py-8" style={{ padding: '16px 0' }} key={"_line_loading_" + i}>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                </tr>
                              ))
                            )
                          )
                        }
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default StockStatus;