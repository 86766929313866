import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { FormGroup, Container, Input, Button, Table, Label, Badge, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, Pagination, PaginationItem, PaginationLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import Select from 'react-select';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import "../datatables.scss";
import useQuery from '../../../helpers/JIFI/useQuery';
import moment from 'moment';
import SKUToolTip from "../helpers/SKUToolTip";
import useSKUStatus from "../helpers/useSKUStatus";
import useSKULevels from "../helpers/useSKULevels";
import useUpcomingStock from "../helpers/useUpcomingStock";

const perPageOptions = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 500, label: '500 - (WARNING SLOW)' },
  { value: 1000, label: '1000 - (WARNING SLOW)' },
]

const fulfillmentToBadge = (fulfillment) => {
  if (fulfillment === 'unfulfilled') return 'warning'
  if (fulfillment === 'awaiting-stock') return 'secondary'
  const conversion = {
    'fulfilled': 'success',
    'partially_fulfilled': 'error',
  }
  return conversion[fulfillment]
}

const priorityColor = (priority) => {
  let check = priority?.toLowerCase()
  if (check === 'high') return 'danger'
  if (check === 'medium') return 'warning'
  if (check === 'low') return 'secondary'
  if (check !== 'low' && check !== 'high' && check !== 'medium') return 'info'
}

const pickToName = (pick) => {
  const conversion = {
    'none': 'UNPICKED',
    'monogram': 'TO-MONOGRAM',
    'fulfillment': 'TO-SHIP',
    'EWE': 'EWE',
    'QV': 'QV',
    'EWESYD': 'EWE Sydney',
    'B360': 'B360',
    'UNIS': 'UNIS',
    'EWE-MONO': 'EWE-MONO',
    'AIRBOX': 'Airbox',
    'AIRBOX-MONO': 'Airbox Monogram',
  }
  return conversion[pick] || pick
}

const pickToBadge = (pick) => {
  const conversion = {
    'none': 'warning',
    'monogram': 'info',
    'fulfillment': 'success',
    'EWE': 'success',
    'QV': 'success',
    'B360': 'success',
    'EWESYD': 'success',
    'UNIS': 'success',
    'EWE-MONO': 'info',
    'AIRBOX': 'success',
    'AIRBOX-MONO': 'info',
  }
  return conversion[pick] || 'success'
}

const paginate = function (array, index) {
  return [
    array[index - 2],
    array[index - 1],
    array[index],
    array[index + 1],
    array[index + 2]
  ].filter(function (el) {
    return el != null;
  });
}

const TablePagination = ({ count, page, setPage, perPage, setPerPage }) => {
  const pageCount = Math.ceil(count / perPage)
  const pages = paginate(Array.from({ length: pageCount }, (v, i) => i + 1), page)
  const handleNumberClicked = (key) => {
    setPage(key)
  }
  return (
    <Pagination className="pagination pagination-rounded justify-content-end mb-2">
      <div style={{ height: '100%', width: 140 }}>
        <Select
          options={perPageOptions}
          onChange={newPerPage => setPerPage(newPerPage.value)}
          value={perPageOptions.find(val => val.value === perPage)}
        />
      </div>
      <PaginationItem disabled>
        <PaginationLink previous />
      </PaginationItem>
      {
        pages.map(val => (
          <PaginationItem onClick={() => handleNumberClicked(val)} active={val === page}>
            <PaginationLink>
              {val}
            </PaginationLink>
          </PaginationItem>
        ))
      }
      <PaginationItem>
        <PaginationLink next />
      </PaginationItem>
    </Pagination>
  )
}

const OrdersPage = ({ url, initValues }) => {
  const findSKULevel = useSKULevels()
  const findUpcomingStock = useUpcomingStock()
  const findSKUStatus = useSKUStatus()

  const initialState = {
    selectedRegion: null,
    filterRegion: null,
    selectedPickStatus: null,
    filterPickStatus: null,
    selectedFlagType: null,
    filterFlagType: null,
    selectedStatus: null,
    filterStatus: null,
    selectedPriority: null,
    filterPriority: null,
    searchTerm: '',
    orderStartDate: '',
    filterStartDate: '',
    orderEndDate: '',
    filterEndDate: '',
    stepOptions: [{ label: 'Unfulfilled', value: 'unfulfilled' }, { label: 'Fulfilled', value: 'fulfilled' }, { label: 'Partially fulfilled', value: 'partially-fulfilled' }, { label: 'Cancelled', value: 'cancelled' },],
    shippingOptions: [{ label: 'Standard', value: 'standard' }, { label: 'Express', value: 'express' }, { label: 'QV Pickup', value: 'pickup' }, { label: 'HQ Pickup', value: 'pickup_hq' }, { label: 'Uber', value: 'Uber' }, { label: 'Overnight', value: 'overnight' }],
    pickStatusOptions: [{ label: 'Unpicked', value: 'none' }, { label: 'To Ship', value: 'fulfillment' }, { label: 'To Monogram', value: 'monogram' }, { label: 'EWE Monogram', value: 'EWE-MONO' }, { label: 'EWE', value: 'EWE' }, { label: 'EWE Sydney', value: 'EWESYD' }, { label: 'B360', value: 'B360' }, { label: 'UNIS', value: 'UNIS' }],
    priorityOptions: [{ label: 'Low', value: 'low' }, { label: 'Medium', value: 'medium' }, { label: 'High', value: 'high' }],
    flagTypesOptions: [{}]
  }

  const [stateData, setStateData] = useState(initValues || initialState);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [flagTypesFilters] = useQuery({
    url: `/orders/getFlagTypes`
  });

  useEffect(() => {
    if (flagTypesFilters) {
      let flagFiltersOptions = flagTypesFilters.map((e) => ({ label: e.name, value: e._id }))
      setStateData((prevState => {
        return {
          ...prevState,
          flagTypesOptions: flagFiltersOptions
        }
      }))
    }
  }, [flagTypesFilters])


  useEffect(() => {
    setStateData(initValues)
  }, [initValues])

  const handleTermChange = (e) => {
    const newTerm = e.currentTarget.value.toUpperCase().replace('#', '')
    setStateData((prevState => {
      return {
        ...prevState,
        searchTerm: newTerm
      }
    }))
  }

  const handleSelectFlagType = selectedFlagType => {
    console.log(selectedFlagType)
    setStateData((prevState => {
      return {
        ...prevState,
        selectedFlagType: selectedFlagType,
        filterFlagType: Array.isArray(selectedFlagType) && selectedFlagType.map(a => a.value)
      }
    }))
  };


  const handleSelectPickStatus = selectedPickStatus => {
    setStateData((prevState => {
      return {
        ...prevState,
        selectedPickStatus: selectedPickStatus,
        filterPickStatus: Array.isArray(selectedPickStatus) && selectedPickStatus.map(a => a.value)
      }
    }))
  };

  const handleSelectStatus = selectedStatus => {
    setStateData((prevState => {
      return {
        ...prevState,
        selectedStatus: selectedStatus,
        filterStatus: Array.isArray(selectedStatus) && selectedStatus.map(a => a.value)
      }
    }))
  };

  const handleSelectPriority = selectedPriority => {
    setStateData((prevState => {
      return {
        ...prevState,
        selectedPriority: selectedPriority,
        filterPriority: Array.isArray(selectedPriority) && selectedPriority.map(a => a.value)
      }
    }))
  };

  const handleSelectRegion = selectedRegion => {
    setStateData((prevState => {
      return {
        ...prevState,
        selectedRegion: selectedRegion,
        filterRegion: Array.isArray(selectedRegion) && selectedRegion.map(a => a.value)
      }
    }))
  };

  const ResetField = e => {
    e.preventDefault();
    setStateData(prevState => {
      return {
        ...prevState,
        selectedRegion: null,
        filterRegion: null,
        selectedPickStatus: null,
        filterPickStatus: null,
        selectedFlagType: null,
        filterFlagType: null,
        selectedStatus: null,
        filterStatus: null,
        selectedPriority: null,
        filterPriority: null,
        searchTerm: '',
        orderStartDate: '',
        filterStartDate: '',
        orderEndDate: '',
        filterEndDate: '',
      };
    });
  };

  const handleChange = (e) => {
    e.persist();
    setStateData((prevState => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
        filterStartDate: e.target.name === 'orderStartDate' ? moment(e.target.value).format('YYYY-MM-DD') : prevState.filterStartDate,
        filterEndDate: e.target.name === 'orderEndDate' ? moment(e.target.value).format('YYYY-MM-DD') : prevState.filterEndDate
      }
    }))
  };

  const [refresh, setRefresh] = useState(true)
  const refreshData = () => {
    setRefresh(prev => !prev)
  }
  const [data, state] = useQuery({ refresh, url, queryData: { page: page, perPage: perPage, searchTerm: stateData.searchTerm, filterStatus: stateData.filterStatus, filterStartDate: stateData.filterStartDate, filterEndDate: stateData.filterEndDate, filterPickStatus: stateData.filterPickStatus, filterFlagType: stateData.filterFlagType, filterRegion: stateData.filterRegion, filterPriority: stateData.filterPriority } });
  const orders = data && data.data;
  const count = data && data.count;

  const { orderStartDate, orderEndDate, selectedStatus, searchTerm, stepOptions, pickStatusOptions, selectedPickStatus, selectedFlagType, flagTypesOptions, selectedPriority, priorityOptions, selectedRegion, regionOptions } = stateData;

  const [selected, setSelected] = useState([])

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Fulfillment" breadcrumbItem="Corporate Orders" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="5">
                      <FormGroup className="select2-container">
                        <Label className="control-label">FILTER</Label>
                        <div className="search-box mb-2 d-block">
                          <div className="position-relative">
                            <Input type="text" className="form-control" placeholder="Search..." value={searchTerm} onChange={handleTermChange} />
                            <i className="bx bx-search-alt search-icon"></i>
                          </div>
                        </div>
                      </FormGroup>
                    </Col>

                    <Col sm="3">
                      <FormGroup className="select2-container">
                        <Label className="control-label">Priority</Label>
                        <Select classNamePrefix="select2-selection" value={selectedPriority} onChange={handleSelectPriority} placeholder="All" title="Priority" options={priorityOptions} isMulti />
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup className="select2-container">
                        <Label className="control-label">Region</Label>
                        <Select classNamePrefix="select2-selection" value={selectedRegion} onChange={handleSelectRegion} placeholder="All" title="Region" options={regionOptions} isMulti />
                      </FormGroup>
                    </Col>

                    <Col sm="1" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                      <FormGroup className="select2-container">
                        <Button type="button" color="info" className="btn-rounded waves-effect waves-light" onClick={ResetField} > Reset</Button>
                      </FormGroup>
                      {/* <FormGroup className="select2-container mt-4">
                        <Button type="button" color="success" className="btn-rounded waves-effect waves-light mb-2 mr-2"><i className="mdi mdi-plus mr-1"></i> Add New Order</Button>
                      </FormGroup> */}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="2">
                      <FormGroup className="select2-container">
                        <Label className="control-label">STEP</Label>
                        <Select classNamePrefix="select2-selection" value={selectedStatus} onChange={handleSelectStatus} placeholder="All" title="Country" options={stepOptions} isMulti />
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup className="select2-container">
                        <Label className="control-label">PICK STATUS</Label>
                        <Select classNamePrefix="select2-selection" value={selectedPickStatus} onChange={handleSelectPickStatus} placeholder="All" title="Status" options={pickStatusOptions} isMulti />
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup className="select2-container">
                        <Label className="control-label">Flag Type</Label>
                        <Select classNamePrefix="select2-selection" value={selectedFlagType} onChange={handleSelectFlagType} placeholder="All" title="Status" options={flagTypesOptions} isMulti />
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup className="select2-container">
                        <Label className="control-label">STARTING</Label>
                        <input className="form-control" name="orderStartDate" value={orderStartDate} onChange={handleChange} type="date" defaultValue="2019-08-19" id="example-date-input" />
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup className="select2-container">
                        <Label className="control-label">ENDING</Label>
                        <input className="form-control" name="orderEndDate" value={orderEndDate} onChange={handleChange} type="date" defaultValue="2019-08-19" id="example-date-input" />
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="table-responsive">
                    <Table className="table table-centered table-nowrap">
                      <thead className="thead-light">
                        <tr>
                          <th>Order ID</th>
                          <th>Created At</th>
                          <th>Due Date</th>

                          <th>Region</th>
                          <th>Pick Status</th>
                          <th>Fulfillment</th>
                          <th>Priority</th>
                          <th>Contains Personalisation</th>
                          <th>SKUs</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          state === 'success' && (
                            orders && orders.map((order, key) => {
                              let skuCount = {}
                              order.line_items.map(val => {
                                if (skuCount[val.sku] === undefined) {
                                  skuCount[val.sku] = val.quantity
                                } else {
                                  skuCount[val.sku] += val.quantity
                                }
                              })

                              const lineSum = Object.keys(skuCount).map(sku => {
                                return {
                                  sku: sku,
                                  quantity: skuCount[sku]
                                }
                              })

                              return lineSum.map((line, keyLine) => keyLine === 0 ? (
                                <tr style={order.flagged ? { background: "#fde1e1" } : {}} key={"_order_" + key}>
                                  <td><Link to={`/corp-orders/${order['_id']}`} className="text-body font-weight-bold">{order['name']}</Link></td>


                                  <td> {(new Date(order.orderCreated)).toDateString()} </td>
                                  <td> {order.deadline && (new Date(order.deadline)).toDateString()} </td>

                                  <td> {order?.shipping_address?.country} </td>
                                  <td>
                                    <Badge className={"font-size-12 badge-soft-" + pickToBadge(order.pick_status)} color={pickToBadge(order.pick_status)} pill>
                                      <i className={"fab " + order.methodIcon + " mr-1 font-size-12 badge-soft-primary"}></i> {pickToName(order.pick_status)}
                                    </Badge>
                                  </td>
                                  <td>
                                    <Badge className={"font-size-12 badge-soft-" + fulfillmentToBadge(order.fulfillment_status)} color={fulfillmentToBadge(order.fulfillment_status)} pill>
                                      <i className={"fab " + order.methodIcon + " mr-1 font-size-12 badge-soft-primary"}></i> {order.fulfillment_status ? order?.fulfillment_status?.replace('_', ' ').toUpperCase() : 'UN-FULFILLED'}
                                    </Badge>
                                  </td>
                                  <td>
                                    {
                                      order?.priority && (
                                        <Badge className={"font-size-12 badge-soft-" + priorityColor(order?.priority)} color={priorityColor(order?.priority)} pill>
                                          <i className={"fab " + order.methodIcon + " mr-1 font-size-12 badge-soft-primary"}></i>{order?.priority}
                                        </Badge>
                                      )
                                    }
                                  </td>
                                  <td>
                                    {
                                      order.contains_monogram === false ? (
                                        <Badge className={"font-size-12 badge-soft-success"} color={'success'} pill>
                                          FALSE
                                        </Badge>
                                      ) : (
                                        <Badge className={"font-size-12 badge-soft-info"} color={'info'} pill>
                                          TRUE
                                        </Badge>
                                      )
                                    }
                                  </td>
                                  <td>
                                    <Badge className={"font-size-12 badge-soft-primary"} color={'primary'} pill>
                                      <SKUToolTip sku={line.sku} findSKULevel={findSKULevel} findUpcomingStock={findUpcomingStock} findSKUStatus={findSKUStatus} />
                                    </Badge> x{line.quantity}
                                  </td>
                                </tr>
                              ) : (
                                <tr style={order.flagged ? { background: "#fde1e1" } : {}} key={"_order_" + key}>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    <Badge className={"font-size-12 badge-soft-primary"} color={'primary'} pill>
                                      <SKUToolTip sku={line.sku} findSKULevel={findSKULevel} findUpcomingStock={findUpcomingStock} findSKUStatus={findSKUStatus} />
                                    </Badge> x{line.quantity}
                                  </td>

                                </tr>
                              )).flat()
                            })
                          )
                        }
                      </tbody>
                    </Table>
                  </div>
                  {
                    (orders && state === 'success') ? (
                      <TablePagination count={count} page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} />
                    ) : (
                      <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                        <div className="spinner-grow text-primary m-1" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default OrdersPage;