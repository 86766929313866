import React, { useState, useEffect, useRef } from 'react';
import { Tooltip } from 'reactstrap'; // Import Tooltip from your library
import moment from 'moment';

const SKUToolTip = ({ sku, findSKULevel, findUpcomingStock, findSKUStatus, placement='top' }) => {
  
  const [toolOpen, setToolOpen] = useState(false)
  

  const handleMouseOver = () => {
    setToolOpen(true);
  };
  
  const handleMouseOut = () => {
    setToolOpen(false);
  };

  const [shiftKeyPressed, setShiftKeyPressed] = useState(false);
  const keyDownHandlerRef = useRef(null);
  const keyUpHandlerRef = useRef(null);
  useEffect(() => {
    if (toolOpen) {
      const handleKeyDown = event => {
        if (event.key === 'Shift') {
          setShiftKeyPressed(true);
        }
      };
  
      const handleKeyUp = event => {
        if (event.key === 'Shift') {
          setShiftKeyPressed(false);
        }
      };
  
      keyDownHandlerRef.current = handleKeyDown;
      keyUpHandlerRef.current = handleKeyUp;
  
      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('keyup', handleKeyUp);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
        window.removeEventListener('keyup', handleKeyUp);
      };
    } else {
      if (keyDownHandlerRef.current) {
        window.removeEventListener('keydown', keyDownHandlerRef.current);
        keyDownHandlerRef.current = null;
      }
      if (keyUpHandlerRef.current) {
        window.removeEventListener('keyup', keyUpHandlerRef.current);
        keyUpHandlerRef.current = null;
      }
    }
  }, [toolOpen]);
  

  const nonPersSKU = sku.endsWith('-P') ? sku.slice(0, -2) : sku;
  const upcomingContainer = findUpcomingStock(nonPersSKU);

  return (
    <span onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} id={`SKU-${sku}`}>
      <span style={{ textDecoration: 'underline', cursor: 'help' }} >{sku}</span>
      <Tooltip placement={placement} isOpen={toolOpen} target={`SKU-${sku}`}  style={{ minWidth: 500 }}>
        {findSKUStatus(nonPersSKU, 'AU') === undefined || findSKULevel(nonPersSKU, 'AU') === undefined || upcomingContainer === null ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary m-1" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            {
              !shiftKeyPressed && (
                <div style={{ position: 'absolute', fontSize: 12, top: 0, left: 0, paddingTop: 10, paddingLeft: 8, fontWeight: 500, color: '#FFF', zIndex: 999 }}>
                  Press <b>shift</b> for more info.
                </div>
              )
            }
            <div style={{ padding: 20, fontWeight: 500 }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ flex: 1, marginRight: '20px' }}>
                  <h5 style={{ color: '#FFF', marginBottom: '10px', height: 20 }}></h5>
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ height: 50 }}><b>Online Status:</b></td>
                      </tr>
                      <tr>
                        <td style={{ height: 90 }}><b>Stock Level:</b></td>
                      </tr>
                      <tr>
                        <td style={{ height: 50 }}><b>Next Container:</b></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style={{ flex: 1, marginRight: '20px' }}>
                  <h5 style={{ color: '#FFF', marginBottom: '10px', height: 20 }}>AU/NZ</h5>
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ height: 50 }}>
                          <span className={`text-${findSKUStatus(nonPersSKU, 'AU') === 'Available' ? 'success' : 'danger'}`}>
                            {findSKUStatus(nonPersSKU, 'AU')}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        {
                          shiftKeyPressed ? (
                            <td style={{ height: 90 }}>
                              <div>PENDING: <span className={`text-${findSKULevel(nonPersSKU, 'PENDING AU') > 0 ? 'warning' : 'success'}`}>{findSKULevel(nonPersSKU, 'PENDING AU')}</span></div>
                              <div>HQ: <span className={`text-${findSKULevel(nonPersSKU, 'HQ') <= 0 ? 'danger' : 'success'}`}>{findSKULevel(nonPersSKU, 'HQ')}</span></div>
                              <div>EWE MEL: <span className={`text-${findSKULevel(nonPersSKU, 'EWE MEL') <= 0 ? 'danger' : 'success'}`}>{findSKULevel(nonPersSKU, 'EWE MEL')}</span></div>
                              <div>EWE SYD: <span className={`text-${findSKULevel(nonPersSKU, 'EWE SYD') <= 0 ? 'danger' : 'success'}`}>{findSKULevel(nonPersSKU, 'EWE SYD')}</span></div>
                            </td>
                          ) : (
                            <td style={{ height: 90 }}><span className={`text-${findSKULevel(nonPersSKU, 'AU') <= 0 ? 'danger' : 'success'}`}>{findSKULevel(nonPersSKU, 'AU')}</span></td>
                          )
                        }
                      </tr>
                      <tr>
                        <td style={{ height: 50 }}>{upcomingContainer?.auNextContainer != null ? <span className='text-success'>{moment(upcomingContainer?.auNextContainer?.date).format('DD-MM-YYYY')}</span> : <span className='text-warning'>N/A</span>}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style={{ flex: 1, marginRight: '20px' }}>
                  <h5 style={{ color: '#FFF', marginBottom: '10px', height: 20 }}>US</h5>
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ height: 50 }}>
                          <span className={`text-${findSKUStatus(nonPersSKU, 'US') === 'Available' ? 'success' : 'danger'}`}>
                            {findSKUStatus(nonPersSKU, 'US')}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        {
                          shiftKeyPressed ? (
                            <td style={{ height: 90 }}>
                              <div>PENDING: <span className={`text-${findSKULevel(nonPersSKU, 'PENDING US') > 0 ? 'warning' : 'success'}`}>{findSKULevel(nonPersSKU, 'PENDING US')}</span></div>
                              <div>UNIS: <span className={`text-${findSKULevel(nonPersSKU, 'UNIS') <= 0 ? 'danger' : 'success'}`}>{findSKULevel(nonPersSKU, 'UNIS')}</span></div>
                              <div>UTAH: <span className={`text-${findSKULevel(nonPersSKU, 'UTAH') <= 0 ? 'danger' : 'success'}`}>{findSKULevel(nonPersSKU, 'UTAH')}</span></div>
                              <div>KENTUCKT: <span className={`text-${findSKULevel(nonPersSKU, 'KENTUCKT') <= 0 ? 'danger' : 'success'}`}>{findSKULevel(nonPersSKU, 'KENTUCKT')}</span></div>
                            </td>
                          ) : (
                            <td style={{ height: 90 }}><span className={`text-${findSKULevel(nonPersSKU, 'US') <= 0 ? 'danger' : 'success'}`}>{findSKULevel(nonPersSKU, 'US')}</span></td>
                          )
                        }
                      </tr>
                      <tr>
                        <td style={{ height: 50 }}>{upcomingContainer?.usNextContainer != null ? <span className='text-success'>{moment(upcomingContainer?.usNextContainer?.date).format('DD-MM-YYYY')}</span> : <span className='text-warning'>N/A</span>}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style={{ flex: 1 }}>
                  <h5 style={{ color: '#FFF', marginBottom: '10px', height: 20 }}>UK</h5>
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ height: 50 }}>
                          <span className={`text-${findSKUStatus(nonPersSKU, 'UK') === 'Available' ? 'success' : 'danger'}`}>
                            {findSKUStatus(nonPersSKU, 'UK')}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        {
                          shiftKeyPressed ? (
                            <td style={{ height: 90 }}>
                              <div>PENDING: <span className={`text-${findSKULevel(nonPersSKU, 'PENDING UK') > 0 ? 'warning' : 'success'}`}>{findSKULevel(nonPersSKU, 'PENDING UK')}</span></div>
                              <div>AIRBOX: <span className={`text-${findSKULevel(nonPersSKU, 'AIRBOX') <= 0 ? 'danger' : 'success'}`}>{findSKULevel(nonPersSKU, 'AIRBOX')}</span></div>
                            </td>
                          ) : (
                            <td style={{ height: 90 }}><span className={`text-${findSKULevel(nonPersSKU, 'UK') <= 0 ? 'danger' : 'success'}`}>{findSKULevel(nonPersSKU, 'UK')}</span></td>
                          )
                        }
                      </tr>
                      <tr>
                        <td style={{ height: 50 }}>{upcomingContainer?.ukNextContainer != null ? <span className='text-success'>{moment(upcomingContainer?.ukNextContainer?.date).format('DD-MM-YYYY')}</span> : <span className='text-warning'>N/A</span>}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
      </Tooltip>
    </span>
  );
};

export default SKUToolTip;
