import React, { useState, useEffect } from 'react';
import { Card, CardBody, Media, Button } from "reactstrap";
import SimpleBar from "simplebar-react";
import { arrayNotNull } from "../../../utilities/utilities";
import moment from 'moment';
import useQuery from '../../../helpers/JIFI/useQuery';
import getTimeInterval from '../../../helpers/human_timespan'

import AddComment from './AddComment'

import superagent from 'superagent'
import {backendUrl} from '../../../helpers/consts.js'

import img1 from "../../../assets/images/companies/img-1.png";

import './skelton.css'
import Dashboard from './index.js';

const CommentHostory = ({initCommentType, initOrderName, title, overrideRefresh}) => {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(100);
    const [commentType, setCommentType] = useState(initCommentType);
    const [orderName, setOrderName] = useState(initOrderName ? initOrderName.replace('#', '') : '');

    useEffect(() => {
        setOrderName(initOrderName ? initOrderName.replace('#', '') : '')
    }, [initOrderName])

    const [refr, setRefresh] = useState(false);
    const refresh = () => setRefresh(prev => !prev)

    useEffect(() => {
        refresh()
    }, [overrideRefresh])

    const endpointLookup = {
        Dashboard: `/comments/general`,
        Order: `/comments/order`,
        Container: `/comments/container`,

        Factory: `/comments/factories`,
        PurchaseOrder: `/comments/purchaseOrders`,
        Release: `/comments/releases`,

    }

    console.log(endpointLookup)
    console.log(commentType)
    console.log(initCommentType)
    console.log(endpointLookup[commentType])

    const [data, state] = useQuery({ 
        url: endpointLookup[commentType], 
        queryData: { 
            page: page, 
            perPage: perPage, 
            orderName: orderName.split('-')[0]
        },
        refresh: refr
    });
    const comments = data && data.data;

    const user = JSON.parse(localStorage.getItem("authUser"));

    const deleteComment = (id) => {
        superagent.post(`${backendUrl}/comments/${id}/delete`)
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
        })
        .then(response => {
            const code = response.status
            if (code > 400) {
            } else {
                console.log(response.body)
            refresh()
            }
        }).catch(err => {
            console.log(err)
        });
    }

    const Body = () => {
        return (
            <div className="mt-4">
                <h4 className="card-title mb-4">{title ? title : 'Notifications'}</h4>
                <SimpleBar style={{ maxHeight: "700px" }}>
                    <ul className="list-group">
                        {(state === 'success' && (arrayNotNull(comments)) ? comments.map((comment, key) => {
                            
                            let isMe = ( user._id === comment.user_model?._id) || (user.email === 'alex.n@july.com' || user.email === 'leo@july.com') || (user?.permissions?.includes('DeleteAnyComment') )
                            if (comment.visible === false) return <></>
                            return (
                                <li key={`_comment_${key}`} className="list-group-item">
                                    <Media>
                                        <div className="avatar-xs mr-3">
                                            <span className="avatar-title rounded-circle bg-light">
                                                <img src={comment.user_model?.profile_image || '/logo-j.svg'} alt="" height="32" width="32" style={comment.user_model?.profile_image ? {objectFit: 'cover', width: 32, height: 32}: {width: 16, height: 16, margin: 8}} className="rounded-circle"/>
                                            </span>
                                        </div>
                                        <Media body style={{whiteSpace: 'pre-wrap'}}>
                                            <h5 className="font-size-14"></h5>
                                            {/* {
                                                comment.description.split('\n').map((item, i) => (<p className="text-muted" key={i}>{item}</p>))
                                            } */}
                                            {
                                                (arrayNotNull(comment.files) &&  (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(comment.files[0]) === false) ? (
                                                    <p className="text-muted"><a target="blank" href={comment.files[0]}> {comment.description || 'Attached file'} </a> </p>
                                                ) : (
                                                    <p className="text-muted">{comment.description}</p>
                                                )
                                            }
                                            
                                            {arrayNotNull(comment.files) ?
                                                <div>
                                                    {
                                                        comment.files.map((img, index) => {
                                                            return (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(img) ? <img src={img} alt="" width="100%" key={index} /> : <></>
                                                        })
                                                    }
                                                </div> : null}
                                            <div className="float-right">
                                                <p className="text-muted mb-0"> {getTimeInterval(new Date(comment.comment_date))}  </p>
                                            </div>
                                            <p className="text-muted mb-0"><i className="mdi mdi-account mr-1"></i>{comment.user_model?.userName || comment.userName} {(comment.orderNameFull !== undefined && orderName !== comment.orderNameFull) && <span className="">({comment.orderNameFull})</span>}</p>
                                        </Media>
                                        {
                                            isMe && <Button onClick={() => deleteComment(comment._id)} style={{marginLeft: 12}} close />
                                        }
                                    </Media>
                                </li>
                            )
                        }) : (
                            <li key={`_comment_${0}`} className="list-group-item">
                                <Media>
                                    <div className="avatar-xs mr-3">
                                        <span className="avatar-title rounded-circle bg-light">
                                            <img alt="" height="32" width="32" className="rounded-circle"/>
                                        </span>
                                    </div>
                                    <Media body style={{whiteSpace: 'pre-wrap'}}>
                                        <h5 className="font-size-14"></h5>
                                        <p className="text-muted placeholder"></p>
                                        <div className="float-right">
                                            <p className="text-muted mb-0 placeholder"> </p>
                                        </div>
                                        <p className="text-muted mb-0 placeholder"></p>
                                    </Media>
                                </Media>
                            </li>
                        ))
                        }
                    </ul>
                </SimpleBar>
                <br/>
                <AddComment orderName={orderName} refresh={refresh} commentType={commentType}/>
            </div>
        )
    }
    if (initCommentType === 'Dashboard') {
        return <Body />
    }
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <Body />
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

export default CommentHostory;