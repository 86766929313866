import { useState } from 'react';
import useQuery from '../../../helpers/JIFI/useQuery';

const useSKULevels = () => {
    const [allStockLevels, allStockLevelState] = useQuery({
        url: `/product/all-stock-v2`,
    });

    const [pendingDataLoaded, pendingState] = useQuery({
        url: `/inventory/pendingStock`,
    });
    const pendingData = pendingDataLoaded || {}

    const [pendingDataLoadedUS, pendingUSState] = useQuery({
        url: `/inventory/pendingStockUS`,
    });
    const pendingDataUS = pendingDataLoadedUS || {}

    const [pendingDataLoadedUK, pendingUKState] = useQuery({
        url: `/inventory/pendingStockUK`,
    });
    const pendingDataUK = pendingDataLoadedUK || {}

    const findSKULevel = (sku, region) => {
        let stockLevels;
        if (!allStockLevels) {
            return null
        }

        const findSKULine = allStockLevels.find(val => val[0] === sku)

        if (findSKULine === undefined) {
            return null
        }


        if (findSKULine) {
            const stockLevelCount = findSKULine.slice(1)

            stockLevels = {
                'PENDING AU': (pendingData?.[findSKULine[0]] || 0),
                'PENDING US': (pendingDataUS?.[findSKULine[0]] || 0),
                'PENDING UK': (pendingDataUK?.[findSKULine[0]] | 0),

                'HQ': stockLevelCount[0],
                'EWE MEL': stockLevelCount[2],
                'EWE SYD': stockLevelCount[5],
                'UNIS': stockLevelCount[4],
                'UTAH': stockLevelCount[12],
                'KENTUCKT': stockLevelCount[11],
                'AIRBOX': stockLevelCount[9],

                'AU': stockLevelCount[0] + stockLevelCount[2] + stockLevelCount[5] - (pendingData?.[findSKULine[0]] || 0),
                'US': stockLevelCount[4] + stockLevelCount[11] + stockLevelCount[12] - (pendingDataUS?.[findSKULine[0]] || 0),
                'UK': stockLevelCount[9] - (pendingDataUK?.[findSKULine[0]] | 0)
            }
        }

        return stockLevels[region]
    }
    return findSKULevel
};

export default useSKULevels;
