import React, { Component, useState, useEffect } from "react";
import { Table, Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import superagent from 'superagent'
import Breadcrumbs from '../../components/Common/Breadcrumb';
import useQuery from '../../helpers/JIFI/useQuery';
import {backendUrl} from '../../helpers/consts.js'
import Select from "react-select";

const numberWithSign = (number) => {
  let signed = ''
  const positive = number > 0
  if(positive){
      signed += '+'
  }
  signed += number
  return <span className={`text-${positive || number === 0 ? 'success' : 'danger'}`}> {signed} </span>
} 

const EditableTables = () => {
  const history = useHistory();
  const [selectedLocation, setSelectedLocation] = useState()

  const [formData, setFormData] = useState(null)
  const setCount = (x, y ,z, v) => {
    setFormData(prev => {
      let newData = {...prev}
      newData[x][y][z] = v
      return newData
    })
  }

  const [refresh, setRefresh] = useState(true)
  const refreshData = () => {
    setRefresh(prev => !prev)
  }

  const [submittingKey, setSubmittingKey] = useState(undefined)

  const [data, state] = useQuery({
    url: `/product`,
    refresh
  });
  const productData = data && data.data

  const [variantCount, setVariantCount] = useState({})
  const [dataStock, stateStock] = useQuery({
    url: `/inventory/fetchStockCount`,
    refresh,
    queryData: {
      location: selectedLocation
    }
  });
  useEffect(() => {
    if (dataStock){
      let count = {}
      dataStock.map((productCount) => {
        productCount.variantCounts.map((variantCount) => {
          const sku = variantCount.variant.sku
          count[sku] = variantCount.count
        })
      })
      // console.log(count)
      setVariantCount(count)
    }
  }, [dataStock])


  useEffect(() => {
    if (productData) {
      if (submittingKey === undefined) {
        setFormData(productData.map((product) => product.variants.map(() => [null, null, null, null])))
      } else {
        setFormData(prev => {
          const data = productData.map((product, key) => {
            if (key !== submittingKey){
              return prev[key]
            }
            return product.variants.map(() => [null, null, null, null])
          })
          // console.log(data)
          return data
        })
        setSubmittingKey(undefined)
      }
    }
  }, [productData])

  const auditSubmitted = (key) => {
    setSubmittingKey(key)
    const product = productData[key]
    const productID = product._id
    const count = formData[key].map((countItem, variantKey) => ({
      variant: product.variants[variantKey]._id,
      on_hand: countItem[0],
      display: countItem[1],
      dead: countItem[2],
      comment: countItem[3],
    }))

    superagent.post(`${backendUrl}/inventory/audit/?location=${selectedLocation}`)
      .send({
        count,
        product: productID
      })
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
          const code = response.status
          history.replace(history.location.pathname, {
              errorStatusCode: code
          });
      })
      .then(response => {
      const code = response.status
      if (code > 400) {
          history.replace(history.location.pathname, {
          errorStatusCode: code
          });
      } else {
        refreshData()
      }
      }).catch(err => {
      console.log(err)
      });
  }

  const [locations, stateLocations] = useQuery({
    url: '/inventory/getAllLocationNames'
  })

  const [pendingData, pendingState] = useQuery({
    url: `/inventory/pendingApprovals/`,
    refresh,
    queryData: {
      location: selectedLocation
    }
  });

  const [location, stateLocation] = useQuery({
    url: `/inventory/locationByName/`,
    queryData: {
      name: selectedLocation || 'HQ2'
    }
  })
  const isCurrentLocation = location?.name === selectedLocation

  let [shopifySkus, setShopifySkus] = useState({})
  useEffect(() => {
    if (location?.name === selectedLocation && location?.shopify_links.length > 0) {
      let shopifyLink = location.shopify_links.find(val => val.sync_type === 'PULL')
      if (shopifyLink) {
        let skus = {}
        shopifyLink.locationVariantIds.map(variantLine => {
          const sku = variantLine.sku
          skus[sku] = variantLine.inventory_item_id
        })
        setShopifySkus(skus)
      } else {
        setShopifySkus(null)
      }
    }
  }, [location])

  console.log(shopifySkus)

  

  if (!locations) return <></>

  const locationOptions = [{value: undefined, label: 'Please select your location.'}, ...locations.map(val => ({value: val.name, label: val.display_name}))]
  const locationValue = locationOptions.find(val => val.value === selectedLocation)

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Inventory" breadcrumbItem="Stock Audit" />
          <Row className="mb-2">
            <Col sm="12">
              <Select 
                options={locationOptions}
                value={locationValue}
                onChange={(e) => setSelectedLocation(e.value)}
              
              />
            </Col>
          </Row>
          
          <Row>
            <Col>
              {
                isCurrentLocation && selectedLocation && formData && productData && productData.map((product, productKey) => {
                  const variantLines = product.variants.sort((a, b) => a.sku.localeCompare(b.sku)).map((variant, variantKey) => {
                    const onHandDiff = variantCount && variantCount[variant.sku] && parseInt(formData[productKey][variantKey][0] || '0') - variantCount[variant.sku].on_hand
                    
                    if (shopifySkus === null || shopifySkus && shopifySkus[variant.sku]) {
                      return (
                        <tr>
                            <th scope="row">{variant.sku}</th>
                            <td><input value={formData[productKey][variantKey][0]} onChange={(e) => setCount(productKey, variantKey, 0, e.target.value)} type="number" step={1} min={0} className="form-control"/> {stateStock !== 'loading' ? formData[productKey][variantKey][0] && onHandDiff && numberWithSign(onHandDiff) : (formData[productKey][variantKey][0] && <div style={{ width: '10%', display: 'flex', justifyContent: 'center', padding: 4 }}> <div className="spinner-grow text-primary" role="status"> <span className="sr-only">Loading...</span> </div> </div>)}</td>
                            <td><input value={formData[productKey][variantKey][1]} onChange={(e) => setCount(productKey, variantKey, 1, e.target.value)} type="number" step={1} min={0} className="form-control"/></td>
                            <td><input value={formData[productKey][variantKey][2]} onChange={(e) => setCount(productKey, variantKey, 2, e.target.value)} type="number" step={1} min={0} className="form-control"/></td>
                            <td><input value={formData[productKey][variantKey][3]} onChange={(e) => setCount(productKey, variantKey, 3, e.target.value)} className="form-control"/></td>
                        </tr>
                      )
                    }
                    return undefined
                  }).filter(val => val !== undefined)

                  if (
                    variantLines.length == 0 
                    // || product.category === 'SET'
                  ) return <></>

                  return (
                    <Card>
                        <CardBody>
                          <CardTitle>{product.name} Stock</CardTitle>
                          {!pendingData?.includes(`${product._id}`) ? (
                          <>
                            <div className="table-responsive">
                                <Table className="table mb-0">

                                    <thead>
                                        <tr>
                                            <th>SKU</th>
                                            <th>Stock On Hand</th>
                                            <th>Display Stock</th>
                                            <th>Dead Stock</th>
                                            <th>Comment</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                          variantLines
                                        }
                                    </tbody>
                                </Table>
                            </div>
                            {
                              (pendingState === 'loading' || submittingKey === productKey) ? (
                                <Button type="button" disabled={true} color="primary" className="btn-lg btn-rounded float-right">
                                  Loading
                                </Button>

                              ) : (
                                <Button type="button" color="primary" className="btn-lg btn-rounded float-right" onClick={() => auditSubmitted(productKey)}>
                                  Submit
                                </Button>
                              )
                            }
                          </>
                          ): `pending approval for ${product.name}` }
                        </CardBody>
                    </Card>
                  )
                })
              }
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default EditableTables;
