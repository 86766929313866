import React from 'react'
import { Card, Col, Row, Table } from 'reactstrap';
import { useHistory } from "react-router-dom";

const navItems = [
  {
    name: 'Overview',
    url: '/po-overview',
    lookup: 'overview'
  },
  {
    name: 'Containers',
    url: '/containers',
    lookup: 'container'
  },
  {
    name: 'Releases',
    url: '/releases',
    lookup: 'releases'
  },
  {
    name: 'Purchase Orders',
    url: '/purchase-order',
    lookup: 'purchase-order'
  },
  {
    name: 'Factories',
    url: '/factories',
    lookup: 'factories'
  },
]

const SupplyChainNav = ({
  children,
  commentComponent
}) => {
  const history = useHistory();
  return ( 
    <div className="page-content">
      <Row>
        <Col xs="3" style={{position: 'relative'}}>
          <Card style={{position: 'sticky', top: 128}}>
            <Table>
              {
                navItems.map(val => {
                  const isSelected = window.location.href.includes(val.lookup)
                  return (
                    <tr style={{...(isSelected ? {background: 'black', color: 'white'} : {}), userSelect: 'none', cursor: 'pointer'}} onClick={() => history.push(val.url)}>
                      <td> {val.name}</td> 
                    </tr>
                  )
                })
              }
            </Table>
            {
              commentComponent
            }
          </Card>
        </Col>
        <Col xs="9">
          {children}
        </Col>
      </Row>
    </div>
  );
}
 
export default SupplyChainNav;